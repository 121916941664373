import { assert } from ".";
import { getAgentEventListener } from "../event_handler";
import { AgentErrorEvent } from "./types_code";

const ERROR_BEGIN = 1000;
export const ERROR_DEVICE_NOT_REGISTERED = 1001;
export const ERROR_AGENT_FORMAT = 1002;
export const ERROR_CURRENT_VIDEO_MODE = 1003;
export const ERROR_CURRENT_AUDIO_MODE = 1004;
export const ERROR_TOO_MANY_MEMBER = 1005;
export const ERROR_CANNOT_CONSULT_WHEN_PUSH = 1006;
export const ERROR_NOT_VIDEO_MODE = 1007;
export const ERROR_FORMAT_STREAM_PATH = 1008;
export const ERROR_MEDIA_FILE_FORMAT = 1009;
export const ERROR_LICENSE_NOT_ALLOW = 1010;

export const ERROR_AGENT_OR_PASSWORD = 10001;
export const ERROR_BELONG_QUEUES = 10002;
export const ERROR_IN_LOGIN = 10003;
export const ERROR_NOT_REGISTERED = 10004;
export const ERROR_FETCH_CONFIG = 10005;
export const ERROR_EMPTY_AGENT = 10006;
export const ERROR_TARGET_NUMBER = 10007;
export const ERROR_AGENT_ID = 10008;
export const ERROR_ALREADY_IN_CONFERENCE = 10009;
export const ERROR_WEBRTC_REGISTER_FAILED = 10010;
export const ERROR_BUILD_VS_COMMAND = 10011;
export const ERROR_CONFIG_EXTENSION_PROFILE = 10012;
export const ERROR_TWO_AGENT_IS_SAME = 10013;
export const ERROR_EMPTY_DEVICE = 10014;
export const ERROR_DEVICE_ID = 10015;
export const ERROR_PARAMS_EMPTY = 10016;
export const ERROR_VALUE_OUT_OF_RANGE = 10017;
export const ERROR_OPERATION_HEHAVIOR = 10020;
const ERROR_END = 10100;

export const notifyError = (code: number, message = "") => {
    assert(ERROR_BEGIN < code && code < ERROR_END, `notifyError错误码${code}范围越界`);
    const error: AgentErrorEvent = {
        type: "AgentErrorEvent",
        code: code,
        message: message
    };
    getAgentEventListener(error.type)(error);
}

export const errorMessage = (code: number) => {
    switch (code) {
        case ERROR_DEVICE_NOT_REGISTERED: return "分机尚未登录";
        case ERROR_AGENT_FORMAT: return "客服工号格式错误";
        case ERROR_CURRENT_VIDEO_MODE: return "当前为视频模式";
        case ERROR_CURRENT_AUDIO_MODE: return "当前为音频模式";
        case ERROR_TOO_MANY_MEMBER: return "当前通话人数多于两方不允许推流";
        case ERROR_CANNOT_CONSULT_WHEN_PUSH: return "磋商时不允许推流";
        case ERROR_NOT_VIDEO_MODE: return "视频模式通话才能发起推流";
        case ERROR_FORMAT_STREAM_PATH: return "推流地址格式错误";
        case ERROR_MEDIA_FILE_FORMAT: return "推送的媒体文件格式不支持,仅支持mp4和png";
        case ERROR_LICENSE_NOT_ALLOW: return "不允许的许可操作";
        case ERROR_AGENT_OR_PASSWORD: return "账号或密码错误";
        case ERROR_BELONG_QUEUES: return "工号至少应该属于一个技能组";
        case ERROR_IN_LOGIN: return "已经在登录中";
        case ERROR_NOT_REGISTERED: return "工号尚未登录,请先在SIP话机上登录";
        case ERROR_FETCH_CONFIG: return "获取配置信息错误";
        case ERROR_EMPTY_AGENT: return "坐席工号不能为空";
        case ERROR_TARGET_NUMBER: return "不是有效的电话号码";
        case ERROR_AGENT_ID: return "坐席工号输入错误";
        case ERROR_ALREADY_IN_CONFERENCE: return "已经在会议中";
        case ERROR_WEBRTC_REGISTER_FAILED: return "webrtc注册错误";
        case ERROR_BUILD_VS_COMMAND: return "指令生成错误";
        case ERROR_CONFIG_EXTENSION_PROFILE: return "extension profile 配置错误";
        case ERROR_TWO_AGENT_IS_SAME: return "相同工号登录错误";
        case ERROR_EMPTY_DEVICE: return "分机号不能为空";
        case ERROR_DEVICE_ID: return "分机号输入错误";
        case ERROR_PARAMS_EMPTY: return "参数不为空";
        case ERROR_OPERATION_HEHAVIOR: return "不允许的操作错误";
        default: return `错误代码: ${code}`;
    }
}
