import { UA, WebSocketInterface } from "jssip";
import { causes } from "jssip/lib/Constants";
import {
    AnswerOptions,
    EndEvent,
    IncomingEvent,
    OutgoingEvent,
    ReInviteEvent,
    RTCSession,
} from "jssip/lib/RTCSession";
import { ConnectedEvent, IncomingRTCSessionEvent, OutgoingRTCSessionEvent, RegisteredEvent, UnRegisteredEvent } from "jssip/lib/UA";
import { DisconnectEvent } from "jssip/lib/WebSocketInterface";

export type WebPhoneDeviceConfig = Readonly<{
    sipExtension: string;
    sipHost: string;
    sipPort: number;
    sipWebSocketHost: string;
    sipWebSocketPort: number;
    sipWebsocketSSL: boolean;
    password: string;
    realm: string;
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints
    video: boolean | MediaTrackConstraints;
    isAutoAnswer: boolean;
}>;

export type SIPRegisteredListener = () => void;
export type SIPRegistrationFailedListener = (cause: string) => void;
export type SIPSessionFailedListener = (cause: string) => void;

export class WebPhoneDevice {
    constructor(
        private readonly peerView: HTMLMediaElement,
        private readonly localView: HTMLMediaElement,
        private readonly config: WebPhoneDeviceConfig
    ) {
        const uri = `${config.sipExtension}@${config.sipHost}:${config.sipPort}`;
        const ws_uri = `wss://${config.sipWebSocketHost}:${config.sipWebSocketPort}`;
        try {
            this.ua = new UA({
                // https://jssip.net/documentation/3.9.x/api/ua_configuration_parameters/
                sockets: new WebSocketInterface(ws_uri), // WebSocket(s)
                uri, // SIP URI
                contact_uri: `sip:${uri};transport=wss`, // Contact header field
                password: config.password, // SIP Authentication password
                realm: config.realm, // SIP Authentication realm
                register: true, // register with the SIP domain automatically
                session_timers: false, // disable session timers
            }) // https://jssip.net/documentation/3.9.x/api/ua/#section_events
                .on("connected", this.connectedListener)
                .on("disconnected", this.disconnectedListener)
                .on("registered", () => this.registeredListener())
                .on("unregistered", this.unregisteredListener)
                .on("registrationFailed", (e: UnRegisteredEvent) => this.registrationFailedListener(causeText(e.cause)))
                // for registrationExpiring event JsSIP will just re-register as usual
                .on("newRTCSession", this.newRTCSessionListener);
        } catch (ex: any) {
            console.error(`WebRTC UA配置错误异常`);
        }
    }

    login = (): void => {
        try {
            console.log(`WebRTC登录${this.config.sipExtension}...`);
            this.ua.start();
        } catch (err) {
            console.error(`WebRTC ua.start: ${err.name}: ${err.message}`);
        }
    };

    setVideoView = function (peer, local) {
        this.peerView = document.getElementById(peer);
        this.localView = document.getElementById(local);
    };

    logout = (): void => {
        console.log(`WebRTC登出${this.config.sipExtension}...`);
        this.ua.unregister();
        this.ua.stop();
    };

    call = (outboundNumber: string, video: boolean): void => {
        console.log(`WebRTC外呼${outboundNumber}...`);
        this._isVideo = video;
        const target = `sip:${outboundNumber}@${this.config.sipHost}:${this.config.sipPort}`;
        this.ua.call(target, this.answerOptions(video));
    };

    answer = (video: boolean): void => {
        try {
            console.log(`WebRTC本方接听...`);
            console.assert(!!this.session, `WebRTC answer时session为空`);
            this._isVideo = video;
            this.session.answer(this.answerOptions(video));
        } catch (ex: any) {
            console.error(`WebRTC answer时状态错误`);
        }
    };

    hangup = (): void => {
        try {
            console.log(`WebRTC本方挂断...`);
            this.ua.terminateSessions();
        } catch (ex: any) {
            console.error(`WebRTC hangup时状态错误`);
        }
    };

    startShare = (): void => {
        navigator.mediaDevices.getDisplayMedia()
            .then(stream => {
                console.log(`WebRTC开始共享屏幕...`);
                this.replaceLocalStream(stream);
            })
            .catch((err: any) => {
                console.error(`WebRTC开始共享屏幕失败: ${err.name}: ${err.message}`);
            });
    }

    stopShare = (): void => {
        this.getUserMedia()
            .then(stream => {
                console.log(`WebRTC结束共享屏幕...`);
                this.replaceLocalStream(stream);
            })
            .catch((err: any) => {
                console.error(`WebRTC结束共享屏幕失败, ${err.name}: ${err.message}`);
            });
    }

    setSIPRegisteredListener = (l: SIPRegisteredListener): WebPhoneDevice => {
        this.registeredListener = l;
        return this;
    }

    setSIPRegistrationFailedListener = (l: SIPRegistrationFailedListener): WebPhoneDevice => {
        this.registrationFailedListener = l;
        return this;
    }

    setSIPOutgoingFailedListener = (l: SIPSessionFailedListener): WebPhoneDevice => {
        this.registrationFailedListener = l;
        return this;
    }

    private newRTCSessionListener = (e: IncomingRTCSessionEvent | OutgoingRTCSessionEvent) => {
        // for outgoing calls, the RTCPeerConnection is set after calling ua.call().
        // However, for incoming calls the RTCPeerConnection is set after calling session.answer()
        console.assert(!!e.session, `WebRTC on newRTCSession session为空`);
        this.session = e.session;
        if (e.originator === "remote") {
            console.log(`WebRTC呼入: ${e.request.from}`);
            if (this.config.isAutoAnswer) {
                this.answer(this.config.video !== false);
            }
        } else {
            console.log(`WebRTC呼出: ${e.request.to}`);
        }
        this.setSessionListeners();
    };

    // https://jssip.net/documentation/3.9.x/api/session/#section_events
    private setSessionListeners = () => {
        if (this.session === undefined) {
            console.error(`WebRTC setSessionListeners时session为空`);
            return;
        }

        if (this.session.connection) {
            // console.log("WebRTC外呼peerconnection.ontrack");
            this.session.connection.ontrack = this.ontrack;
        } else {
            this.session.on("peerconnection", e => e.peerconnection.ontrack = this.ontrack);
        }

        // Fired when receiving or generating a 1XX SIP class response (>100) to the INVITE request
        this.session.on("progress", (e: IncomingEvent | OutgoingEvent) => { });

        // Fired when the call is accepted (2XX received/sent).
        this.session.on("accepted", (e: IncomingEvent | OutgoingEvent) => {
            const party = e.originator === "remote" ? "对方" : "本方";
            console.log(`WebRTC${party}已接听`);
        });

        // Fired when the call is confirmed (ACK received/sent).
        this.session.on("confirmed", (e: IncomingEvent | OutgoingEvent) => {
            console.log(`WebRTC开始通话`);
            this.startLocalView();
        });

        // Fired when an established call ends.
        this.session.on("ended", (e: EndEvent) => {
            console.log(`WebRTC呼叫结束, 挂机方: ${e.originator} 结束原因: ${e.cause}`);
            this.closeVideoDevice();
            this.stopLocalView();
        });

        // Fired when the session was unable to establish.
        this.session.on("failed", (e: EndEvent) => {
            console.log(`WebRTC会话失败, 错误方: ${e.originator} 错误原因: ${e.cause} 错误SIP消息: ${e.message}`);
            this.sessionFailedListener(e.cause); // TODO 未翻译为中文
        });

        // Fired when an in-dialog reINVITE is received.
        this.session.on("reinvite", (e: ReInviteEvent) => {
            console.log(`WebRTC REINVITE ${e.request}`);
        });
    };

    private startLocalView = () => {
        this.getUserMedia()
            .then(stream => {
                console.assert(this.localStream === undefined, `WebRTC startLocalView时localStream不为空`);
                // console.log(stream);
                // console.log(`⬆localView音视频流⬆`);
                this.localStream = stream;
                if(this.localView){
                    this.localView.onloadedmetadata = () => {
                        this.localView.play()
                        console.log(`localView已播放`);
                    }
                    this.localView.srcObject = stream;
                }
            })
            .catch(err => console.error(`WebRTC显示本地视频错误, ${err.name}: ${err.message}`));
    }

    private stopLocalView = () => {
        this.localStream = undefined;
        if(this.peerView){
            this.peerView.srcObject = null;
        }
        if(this.localView){
            this.localView.srcObject = null;
        }
    };

    private closeVideoDevice = () => {
        if (this.localStream !== undefined) {
            this.localStream.getVideoTracks().forEach(track => {
                track.stop();
                this.localStream.removeTrack(track);
            });
        }
    }

    private ontrack = (ev: RTCTrackEvent) => {
        // console.log(ev.streams[0]);
        // console.log(`⬆peerView音视频流⬆`);
        if(this.peerView){
            this.peerView.load();
            this.peerView.srcObject = ev.streams[0];
            let playPromise = this.peerView.play();
            if (playPromise !== undefined) {
                playPromise.then(() => {
                    this.peerView.play()
                }).catch(()=> {
                   
                })
            }
        }
    };

    private replaceLocalStream = (stream: MediaStream) => {
        console.assert(!!stream, `WebRTC replaceLocalStream stream为空`);
        console.assert(stream.getVideoTracks().length > 0, `WebRTC replaceLocalStream VideoTracks为空`);
        console.assert(!!this.localStream, `WebRTC replaceLocalStream localStream为空`);
        if(this.localView){
            this.localView.srcObject = stream;
        }
        this.closeVideoDevice();

        const [track] = stream.getVideoTracks();
        this.localStream.addTrack(track);
        const sender = this.session.connection.getSenders().find(s => s.track.kind == track.kind);
        console.assert(!!sender, `replaceLocalStream sender为空`);
        sender.replaceTrack(track);
    }

    private answerOptions = (video: boolean): AnswerOptions => {
        console.log(`是否使用摄像头: ${video ? '是' : '否'}`);
        return {
            mediaConstraints: {
                audio: true,
                video: video
            },
            mediaStream: this.localStream,
            pcConfig: {
                iceServers: [
                    {
                        urls: ['stun:stun.l.google.com:19302', 'stun:stun1.l.google.com:19302']
                    }
                ]
            }
        };
    }

    private getUserMedia = (): Promise<MediaStream> => navigator.mediaDevices.getUserMedia({
        audio: true,
        video: this._isVideo,
    });

    private connectedListener = (e: ConnectedEvent) => {
        console.log(`WebRTC连接成功`);
    };

    private disconnectedListener = (e: DisconnectEvent) => {
        if (e.error) {
            console.error(`WebRTC异常断连, 错误码: ${e.code}, 错误原因: ${e.reason}`);
        } else {
            console.log("WebRTC正常断连");
        }
    };

    private registeredListener: SIPRegisteredListener = () => {
        console.log(`WebRTC注册成功`);
    };

    private unregisteredListener = (e: UnRegisteredEvent) => {
        console.log(`WebRTC已取消注册`);
    };

    private registrationFailedListener: SIPRegistrationFailedListener = (cause: string) => {
        console.error(`WebRTC注册失败, 原因: ${causeText(cause)}`);
    };

    private sessionFailedListener: SIPSessionFailedListener = (cause: string) => {
        console.error(`WebRTC会话失败, 原因: ${causeText(cause)}`);
    };

    private _isVideo = false;
    private readonly ua: UA;
    private session: RTCSession | undefined;
    private localStream: MediaStream | undefined;
}

const causeText = (c: causes | string): string => {
    switch (c) {
        case causes.CONNECTION_ERROR: return "连接错误";
        case causes.REQUEST_TIMEOUT: return "请求超时";
        case causes.SIP_FAILURE_CODE: return "SIP错误码";
        case causes.INTERNAL_ERROR: return "内部错误";
        case causes.BUSY: return "设备忙";
        case causes.REJECTED: return "已拒绝";
        case causes.REDIRECTED: return "已重新转向";
        case causes.UNAVAILABLE: return "设备不可用";
        case causes.NOT_FOUND: return "没有找到设备";
        case causes.ADDRESS_INCOMPLETE: return "地址不完整";
        case causes.INCOMPATIBLE_SDP: return "不兼容的SDP";
        case causes.MISSING_SDP: return "缺少SDP";
        case causes.AUTHENTICATION_ERROR: return "身份认证错误";
        case causes.BYE: return "已结束";
        case causes.WEBRTC_ERROR: return "WebRTC错误";
        case causes.CANCELED: return "已取消";
        case causes.NO_ANSWER: return "无应答";
        case causes.EXPIRES: return "已超时";
        case causes.NO_ACK: return "没有ACK应答";
        case causes.DIALOG_ERROR: return "Dialog对话错误";
        case causes.USER_DENIED_MEDIA_ACCESS: return "用户拒绝访问媒体";
        case causes.BAD_MEDIA_DESCRIPTION: return "媒体描述错误";
        case causes.RTP_TIMEOUT: return "RTP超时";
        default: return `未知注册错误：${c}`;
    }
}