import * as API from "../api/call_control";
const dayjs = require('dayjs');
const axios = require("axios");
import {
    ERROR_OPERATION_HEHAVIOR,
    ERROR_PARAMS_EMPTY,
    ERROR_TARGET_NUMBER,
    ERROR_VALUE_OUT_OF_RANGE,
    allowOperation,
    errorMessage,
    notifyError,
} from "../types";
import {
    addCallConsultEventListener,
    addCallHangupEventListener,
    addCallHoldEventListener,
    addCallRingingEventListener,
    addCallUnHoldEventListener,
    addCallRingBackEventListener,
    addCallOtherPartyCanceledEventListener,
    addThisPartyAnsweredEventListener,
    addOtherPartyAnsweredEventListener,
    addRecvDtmfEventListener,
    addAgentOnCallEventListener,
    addCallMuteEventListener,
    addCallUnMuteEventListener,
    addStartVideoPullEventListener,
    addStopVideoPullEventListener,
    addVideoStreamErrorListener,
    addDetectSpeechEventListener,
    addStartVideoPushEventListener,
    addStopVideoPushEventListener,
    addPublishStreamPrepareEventListener,
    addVoiceChangeEventListener,
} from "../event_handler";
import { getAccessClient, isIllegalOperation } from "../web_socket_client";
import {
    AgentCallConsultEvent,
    AgentCallHoldEvent,
    AgentCallUnHoldEvent,
    AgentCallRingingEvent,
    AgentCallHangupEvent,
    AgentCallRingBackEvent,
    OtherPartyCanceledEvent,
    ThisPartyAnsweredEvent,
    OtherPartyAnsweredEvent,
    RecvDtmfEvent,
    AgentOnCallEvent,
    AgentCallMuteEvent,
    AgentCallUnMuteEvent,
    StartVideoPullEvent,
    StopVideoPullEvent,
    StartVideoPushEvent,
    StopVideoPushEvent,
    frequencyThickMapping,
    frequencyKey,
    frequencyThinMapping,
    VoiceChangeEvent,
} from "../types/types_code";
import { isExtensionNumber, isValidNumber } from "../types/utils";
import { close_customer_view, show_customer_view } from "../subscribe";
import { close_agent_view, push_screen_capture_stream } from "../publish";
import { getWebPhoneDevice } from "../web_phone_device";
import { AgentConfig } from "../configuration";

export class VoiceCommCallControl implements API.CallControl {

    makeCall(target: string, trunkNumber: string = "", gateway: string = "", outno: string = ""): boolean {
        if (isIllegalOperation("make")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-make`);
            return;
        }
        target = target?.trim();
        // if (!isValidNumber(target)) {
        //     notifyError(ERROR_TARGET_NUMBER, `${target}不是有效的外呼号码`);
        //     return;
        // }
        const client = getAccessClient();
        if (client.isThisDevice(target)) {
            notifyError(ERROR_TARGET_NUMBER, `不能外呼到本分机号码`);
            return;
        }
        if (AgentConfig.deviceType === "webphone") {
            // client.sendMakeVoiceCallCommand(target, trunkNumber, gateway, outno);
            return getWebPhoneDevice().call(target, false);
        } else {
            return client.sendMakeVoiceCallCommand(target, trunkNumber, gateway, outno);
        }
    }

    audioAnswer(): boolean {
        getWebPhoneDevice().answer(false);
        return true;
    }

    async answer() {
        return new Promise<boolean>((resolve, reject) => {
            axios.get(AgentConfig.answerToolUrl).then(res => {
                return resolve(true);
            }).catch(err => {
                return resolve(false);
            });
        });
    }

    hangup(): boolean {
        console.log(`当前状态机：${getAccessClient().getMyAllowOperate()}`);
        if (isIllegalOperation("hangup")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-hangup`);
            return;
        }
        if (AgentConfig.deviceType === "webphone") {
            getWebPhoneDevice().hangup();
        }
        return getAccessClient().sendHangupCallCommand();
    }

    hold(): boolean {
        if (isIllegalOperation("hold")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-hold`);
            return;
        }
        return getAccessClient().sendHoldCallCommand();
    }

    unHold(): boolean {
        if (isIllegalOperation("unhold")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-unhold`);
            return;
        }
        return getAccessClient().sendUnHoldCallCommand();
    }

    mute(): boolean {
        if (isIllegalOperation("mute")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-mute`);
            return;
        }
        return getAccessClient().sendMuteCallCommand();
    }

    unMute(): boolean {
        if (isIllegalOperation("unmute")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-unmute`);
            return;
        }
        return getAccessClient().sendUnMuteCallCommand();
    }

    agentEvaluate(callData: string = ""): boolean {
        if (isIllegalOperation("agent_evaluate")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-agent_evaluate`);
            return;
        }
        return getAccessClient().sendAgentEvaluateCommand(callData);
    }

    consult(target: string, callData: string = "", trunkNumber: string = "", gateway: string = "", outno: string = ""): boolean {
        if (isIllegalOperation("consult")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-consult`);
            return;
        }
        target = target?.trim();
        // if (!isValidNumber(target)) {
        //     notifyError(ERROR_TARGET_NUMBER, `${target}不是有效的磋商号码`);
        //     return;
        // }
        const client = getAccessClient();
        if (client.isThisDevice(target)) {
            notifyError(ERROR_TARGET_NUMBER, `不能磋商到本分机号码`);
            return;
        }
        return client.sendConsultCallCommand(target, callData, trunkNumber, gateway, outno);
    }

    proxyAnswer(peerAgentID: string): boolean {
        if (isIllegalOperation("proxy_answer")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-proxy_answer`);
            return;
        }
        peerAgentID = peerAgentID?.trim();
        if (!isExtensionNumber(peerAgentID)) {
            notifyError(ERROR_TARGET_NUMBER, `${peerAgentID}不是有效的坐席号码`);
            return;
        }
        const client = getAccessClient();
        if (client.isThisDevice(peerAgentID)) {
            notifyError(ERROR_TARGET_NUMBER, `不能对本分机进行操作`);
            return;
        }
        return getAccessClient().sendProxyAnswerCommand(peerAgentID);
    }

    retrieve(): boolean {
        if (isIllegalOperation("cancel_consult")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-cancel_consult`);
            return;
        }
        return getAccessClient().sendRetrieveCallCommand();
    }

    completeTransfer(): boolean {
        if (isIllegalOperation("complete_transfer")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-complete_transfer`);
            return;
        }
        return getAccessClient().sendCompleteTransferCommand();
    }

    voiceChangeThick(frequency: frequencyKey, destType: string, value: string, isChangeRecord: string): boolean {
        try {
            if (frequency === "custom" && value != "") {
                const val = parseFloat(value);
                if (val < 0.6 || val > 1) {
                    notifyError(ERROR_VALUE_OUT_OF_RANGE, `粗音赋值超出范围, 应是0.6~1, 精度为2位小数`);
                    return;
                }
            }
        } catch (error) {
            notifyError(ERROR_VALUE_OUT_OF_RANGE, `粗音赋值超出范围, 应是0.6~1, 精度为2位小数`);
            return;
        }
        if (frequency === "custom") {
            return getAccessClient().sendVoiceChangeThickCommand(destType, value, isChangeRecord);
        } else {
            return getAccessClient().sendVoiceChangeThickCommand(destType, frequencyThickMapping[frequency], isChangeRecord);
        }
    }

    voiceChangeThin(frequency: frequencyKey, destType: string, value: string, isChangeRecord: string): boolean {
        try {
            if (frequency === "custom" && value != "") {
                const val = parseFloat(value);
                if (val < 1 || val > 2) {
                    notifyError(ERROR_VALUE_OUT_OF_RANGE, `细音赋值超出范围, 应是1~2, 精度为2位小数`);
                    return;
                }
            }
        } catch (error) {
            notifyError(ERROR_VALUE_OUT_OF_RANGE, `细音赋值超出范围, 应是1~2, 精度为2位小数`);
            return;
        }
        if (frequency === "custom") {
            return getAccessClient().sendVoiceChangeThinCommand(destType, value, isChangeRecord);
        } else {
            return getAccessClient().sendVoiceChangeThinCommand(destType, frequencyThinMapping[frequency], isChangeRecord);
        }
    }

    completeThreeParties(): boolean {
        if (isIllegalOperation("complete_three_parties")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-complete_three_parties`);
            return;
        }
        return getAccessClient().sendCompleteThreePartiesCommand();
    }

    sendDTMF(dtmf: string): boolean {
        dtmf = dtmf?.trim();
        return getAccessClient().sendDTMFCommand(dtmf);
    }

    singleStepTransfer(target: string, callData: string = ""): boolean {
        if (isIllegalOperation("single_step_transfer")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-single_step_transfer`);
            return;
        }
        return getAccessClient().sendSingleStepTransferCommand(target, "agent", callData, "", "", "");
    }

    singleStepTransferPhone(target: string, callData: string = "", trunkNumber: string = "", gateway: string = "", outno: string = ""): boolean {
        if (isIllegalOperation("single_step_transfer_phone")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-single_step_transfer_phone`);
            return;
        }
        return getAccessClient().sendSingleStepTransferCommand(target, "phone", callData, trunkNumber, gateway, outno);
    }

    transferAgentGroup(target: string, callData: string = ""): boolean {
        if (isIllegalOperation("transfer_agent_group")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-transfer_agent_group`);
            return;
        }
        return getAccessClient().sendSingleStepTransferCommand(target, "queue", callData, "", "", "");
    }

    requestVideoMode(): boolean {
        return getAccessClient().sendRequestVideoModeCommand();
    }

    requestAudioMode(): boolean {
        return getAccessClient().sendRequestAudioModeCommand();
    }

    startPushVideoStream(streamPath: string): boolean {
        streamPath = streamPath?.trim();
        if (!streamPath) {
            notifyError(ERROR_PARAMS_EMPTY, errorMessage(ERROR_PARAMS_EMPTY));
            return;
        }
        return getAccessClient().sendStartPushVideoStream(streamPath);
    }

    stopPushMedia(): boolean {
        return getAccessClient().sendStopPushMedia();
    }

    startPushMediaFile(filename: string): boolean {
        filename = filename?.trim();
        if (!filename) {
            notifyError(ERROR_PARAMS_EMPTY, errorMessage(ERROR_PARAMS_EMPTY));
            return;
        }
        return getAccessClient().sendStartPushMediaFile(filename);
    }

    publishLocalStream(elementId: string): void {
        elementId = elementId?.trim();
        if (!elementId) {
            notifyError(ERROR_PARAMS_EMPTY, errorMessage(ERROR_PARAMS_EMPTY));
            return;
        }
        const streamPath = dayjs().format("YYYYMMDD") + "/" + this.getRandomString();
        push_screen_capture_stream(elementId, streamPath);
    }

    getRandomString() {
        var chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
        var nums = "";
        for (var i = 0; i < 32; i++) {
            var id = parseInt(`${Math.random() * 61}`);
            nums += chars[id];
        }
        return nums;
    }

    closeLocalStream(elementId: string) {
        elementId = elementId?.trim();
        if (!elementId) {
            notifyError(ERROR_PARAMS_EMPTY, errorMessage(ERROR_PARAMS_EMPTY));
            return;
        }
        close_agent_view(elementId);
    }

    showCustomerView(elementId: string, href: string): void {
        elementId = elementId?.trim();
        href = href?.trim();
        if (!elementId && !href) {
            notifyError(ERROR_PARAMS_EMPTY, errorMessage(ERROR_PARAMS_EMPTY));
            return;
        }
        show_customer_view(elementId, href);
    }

    closeCustomerView(elementId: string): void {
        elementId = elementId?.trim();
        if (!elementId) {
            notifyError(ERROR_PARAMS_EMPTY, errorMessage(ERROR_PARAMS_EMPTY));
            return;
        }
        close_customer_view(elementId);
    }

    callbackLast(): boolean {
        throw new Error("callbackLast not implement yet");
    }

    setVideoStreamErrorListener(callback: (msg: any) => void): void {
        addVideoStreamErrorListener(callback);
    }

    setDetectSpeechEventListener(callback: (msg: any) => void): void {
        addDetectSpeechEventListener(callback);
    }

    setChannelRingingEventListener(listener: (event: AgentCallRingingEvent) => void): void {
        addCallRingingEventListener(listener);
    }

    setChannelRingBackEventListener(listener: (event: AgentCallRingBackEvent) => void): void {
        addCallRingBackEventListener(listener);
    }

    setChannelThisPartyAnsweredEventListener(listener: (event: ThisPartyAnsweredEvent) => void): void {
        addThisPartyAnsweredEventListener(listener);
    }

    setChannelOtherPartyAnsweredEventListener(listener: (event: OtherPartyAnsweredEvent) => void): void {
        addOtherPartyAnsweredEventListener(listener);
    }

    setChannelDestroyEventListener(listener: (event: AgentCallHangupEvent) => void): void {
        addCallHangupEventListener(listener);
    }

    setChannelHoldEventListener(listener: (event: AgentCallHoldEvent) => void): void {
        addCallHoldEventListener(listener);
    }

    setChannelUnHoldEventListener(listener: (event: AgentCallUnHoldEvent) => void): void {
        addCallUnHoldEventListener(listener);
    }

    setChannelMuteEventListener(listener: (event: AgentCallMuteEvent) => void): void {
        addCallMuteEventListener(listener);
    }

    setChannelUnMuteEventListener(listener: (event: AgentCallUnMuteEvent) => void): void {
        addCallUnMuteEventListener(listener);
    }

    setConsultEventListener(listener: (event: AgentCallConsultEvent) => void): void {
        addCallConsultEventListener(listener);
    }

    setAgentOnCallEventListener(listener: (event: AgentOnCallEvent) => void): void {
        addAgentOnCallEventListener(listener);
    }

    setOtherPartyCanceledEventListener(listener: (event: OtherPartyCanceledEvent) => void): void {
        addCallOtherPartyCanceledEventListener(listener);
    }

    setRecvDtmfEventListener(listener: (event: RecvDtmfEvent) => void): void {
        addRecvDtmfEventListener(listener);
    }

    setStartVideoPullEventListener(listener: (event: StartVideoPullEvent) => void): void {
        addStartVideoPullEventListener(listener);
    }

    setStopVideoPullEventListener(listener: (event: StopVideoPullEvent) => void): void {
        addStopVideoPullEventListener(listener);
    }

    setStartVideoPushEventListener(listener: (event: StartVideoPushEvent) => void): void {
        addStartVideoPushEventListener(listener);
    }

    setStopVideoPushEventListener(listener: (event: StopVideoPushEvent) => void): void {
        addStopVideoPushEventListener(listener);
    }

    setPublishStreamPrepareEventListener(listener: (PublishStreamPrepareEvent: any) => void): void {
        addPublishStreamPrepareEventListener(listener);
    }

    setVoiceChangeEventListener(listener: (event: VoiceChangeEvent) => void): void {
        addVoiceChangeEventListener(listener);
    }
}
