import { AGENT_STATE_ACW, AgentCallRingBackEvent, AgentCallRingingEvent, STATUS_AFTER_WORK, STATUS_INCALL_READY, STATUS_READY } from "../types/types_code";
import { getAccessClient } from "../web_socket_client";
import { Session } from "./session";
import { assert } from "../types";
import { AgentConfig } from "../configuration";

let session: Session = null;

export const createSession = (e: AgentCallRingingEvent | AgentCallRingBackEvent): Session | undefined => {
    if (!getAccessClient().isThisAgent(e.agent_id)) return; // 非登录座席无需创建会话
    const s = new Session(
        e.agent_party,
        e.uuid,
        e.other_party,
        e.is_inbound
    );
    session = s;
    return session;
};

export const currentSession = (): Session | undefined => session;


export const findSession = (id: string): Session | undefined => {
    if (session.id === id) {
        return session;
    } else {
        return undefined;
    }
}

export const destroySession = (id: string, is_inbound: boolean) => {
    assert(!!id, "destroySession id为空");
    const status = AgentConfig.isAfterCall ? (is_inbound ? AGENT_STATE_ACW : STATUS_AFTER_WORK) : STATUS_INCALL_READY;
    getAccessClient().sendSetAgentStatusCommand(status);
    session = null;
};

export const destroySessionByWbsocket = () => {
    session = null;
}