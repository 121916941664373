type frequencyMap = Readonly<{
    low: string;
    middle: string;
    high: string;
    restore: string;
    custom: string;
}>;

export type frequencyKey = keyof frequencyMap;

export const frequencyThickMapping: frequencyMap = {
    low: "0.9",
    middle: "0.8",
    high: "0.7",
    restore: "1",
    custom: ""
}

export const frequencyThinMapping: frequencyMap = {
    low: "1.1",
    middle: "1.3",
    high: "1.5",
    restore: "1",
    custom: ""
}

export type AllowOperation =
    | "make"
    | "answer"
    | "hangup"
    | "hold"
    | "unhold"
    | "mute"
    | "unmute"
    | "consult"
    | "single_step_transfer"
    | "single_step_transfer_phone"
    | "agent_evaluate"
    | "transfer_agent_group"
    | "cancel_consult"
    | "complete_transfer"
    | "complete_three_parties"
    | "create_conference"
    | "add_conference_member"
    | "leave_conference"
    | "login"
    | "logout"
    | "status"
    | "proxy_answer"
    | "force_busy"
    | "force_free"
    | "force_logout"
    | "force_listen"
    | "force_whisper"
    | "force_conference"
    | "force_intercept"
    | "force_hangup";

export type BaseAgentEvent = Readonly<{
    agent_id: string;   //不带@
    device: string;
    date_time: string;
}>

export type BaseCallEvent = BaseAgentEvent & Readonly<{
    uuid: string
    allow: ReadonlyArray<AllowOperation>;
}>

export type AgentNullEvent = BaseAgentEvent &
    Readonly<{
        type: "AgentNull";
    }>;

export const AGENT_NULL_EVENT: AgentNullEvent = {
    type: "AgentNull",
    agent_id: "",
    device: "",
    date_time: ""
} as const;

export type AgentLoginFailedEvent = BaseAgentEvent &
    Readonly<{
        type: "LoginFailedEvent";
        cause: string;
    }>;

export type DetectSpeechEvent = BaseAgentEvent &
    Readonly<{
        type: "DetectSpeechEvent";
        speaker: string;
        content: string;
    }>;

export type LoginQueue = Readonly<{
    queue_id: string;
    is_monitor: boolean;
}>;

export type AgentLoginSuccessEvent = BaseAgentEvent &
    Readonly<{
        type: "LoginSuccessEvent";
        allow: ReadonlyArray<AllowOperation>;
    }>;

// https://developer.mozilla.org/zh-CN/docs/Web/API/CloseEvent#status_codes    
export const WS_CLOSE_NORMAL = 1000;                                // 正常登出
export const WS_CLOSE_GOING_AWAY = 1001;                            // 终端离开，可能因为服务端错误，也可能因为浏览器正从打开连接的页面跳转离开
export const WS_CLOSE_PROTOCOL_ERROR = 1002;                        // 由于协议错误而中断连接
export const WS_CLOSE_UNSUPPORTED = 1003;                           // 由于接收到不允许的数据类型而断开连接
export const WS_CLOSE_NO_STATUS = 1005;                             // 表示没有收到状态码
export const WS_CLOSE_ABNORMAL = 1006;                              // 连接非正常关闭
export const WS_CLOSE_TOO_LARGE = 1009;                             // 由于收到过大的数据帧而断开连接
export const WS_CLOSE_DUPLICATE = 4801;                             // 客户端重复发起连接
export const WS_CLOSE_SAME_AGENT = 4802;                            // 同工号有新的登录
export const WS_CLOSE_BY_MONITOR = 4803;                            // 被班长强制退出
export const WS_CLOSE_BY_UNREG = 4804;                              // SIP话机未注册
export const WS_CLOSE_SAME_DEVICE = 4805;                           // 同分机有新的登录
export const LOGIN_FAILED_DUPLICATE = "login_failed_duplicate";     // 同分机号正在通话中，重复登录失败

export type AgentLogoutEvent = BaseAgentEvent &
    Readonly<{
        type: "Logout",
        code: number,
        cause: string;
        allow: ReadonlyArray<AllowOperation>;
    }>;

// Agent Control
export const STATUS_READY = "0";
export const STATUS_NOT_READY = "1";
export const AGENT_STATE_ACW = "2";
export const STATUS_REST = "3";
export const STATUS_DINNER = "4";
export const STATUS_TRAINING = "5";
export const STATUS_MEETING = "7"
export const STATUS_AFTER_WORK = "9";
export const STATUS_LOGOUT = "10";
export const STATUS_LOGIN = "11";
export const STATUS_TALKING = "12";
export const STATUS_DIALING = "13";
export const STATUS_RINGING = "14";
export const STATUS_INCALL_READY = "16";

const all_agent_status = [
    STATUS_READY,
    STATUS_NOT_READY,
    AGENT_STATE_ACW,
    STATUS_AFTER_WORK,
    STATUS_REST,
    STATUS_DINNER,
    STATUS_TRAINING,
    STATUS_DIALING,
    STATUS_RINGING,
    STATUS_LOGOUT,
    STATUS_LOGIN,
    STATUS_TALKING,
    STATUS_MEETING,
    STATUS_INCALL_READY
] as const;
export type EnumAgentStatus = typeof all_agent_status[number];

export type MemberStatusType = {
    [key: string]: EnumAgentStatus;
};

export type AgentStatusChangeEvent = BaseAgentEvent &
    Readonly<{
        type: "AgentStatusEvent";
        status: EnumAgentStatus;
        isMntForce: boolean;
        allow: ReadonlyArray<AllowOperation>;
    }>;

// CHANNEL_CREATE
export type AgentCallRingingEvent = BaseCallEvent &
    Readonly<{
        type: "RingingEvent";
        agent_party: string;
        other_party: string;
        other_party_uuid?: string;
        // is_consult: boolean;
        is_inbound: boolean;
        status: string;
        user_data: string;
    }>;
export type AgentCallRingBackEvent = BaseCallEvent &
    Readonly<{
        type: "RingBackEvent";
        agent_party: string;
        other_party: string;
        other_party_uuid: string;
        status: string;
        is_consult: boolean;
        is_inbound: boolean;
    }>;

export type AgentCallConsultEvent =
    Readonly<{
        type: "ConsultEvent";
        device: string;
        cause: string;
        status?: string;
        allow: ReadonlyArray<AllowOperation>;
        date_time: string;
    }>;

export type ThisPartyAnsweredEvent = BaseCallEvent &
    Readonly<{
        type: "ThisPartyAnsweredEvent";
        other_party: string;
        other_party_uuid: string;
        is_inbound: boolean;
        status: string;
        video_stream: string;
        user_data: string;
    }>;

export type MonitorForceActionAnsweredEvent = BaseCallEvent &
    Readonly<{
        type: "MonitorForceActionAnsweredEvent";
        other_device: string;
        status: string;
        force_type: string;
        video_stream: string;
    }>;

export type OtherPartyAnsweredEvent = BaseCallEvent &
    Readonly<{
        type: "OtherPartyAnsweredEvent";
        other_party: string;
        other_party_uuid: string;
        is_inbound: boolean;
        status: string;
        video_stream: string;
        user_data: string;
    }>;

// CHANNEL_DESTROY
export type AgentCallHangupEvent = BaseCallEvent &
    Readonly<{
        type: "HangupEvent";
        other_party: string;
        user_data: string;
    }>;

// CHANNEL_HOLD
export type AgentCallHoldEvent = BaseCallEvent &
    Readonly<{
        type: "HoldEvent";
    }>;

// CHANNEL_UNHOLD
export type AgentCallUnHoldEvent = BaseCallEvent &
    Readonly<{
        type: "UnholdEvent";
    }>;

export type AgentCallMuteEvent = BaseCallEvent &
    Readonly<{
        type: "MuteEvent";
    }>;

export type AgentCallUnMuteEvent = BaseCallEvent &
    Readonly<{
        type: "UnMuteEvent";
    }>;

export type OtherPartyCanceledEvent =
    Readonly<{
        type: "OtherPartyCanceledEvent";
        device: string;
        cause: string;
        status: string;
        allow: ReadonlyArray<AllowOperation>;
        date_time: string;
    }>;

export type AllQueueListEvent = BaseAgentEvent &
    Readonly<{
        type: "AllQueueListEvent";
        list: Array<string>;
    }>;

type queueAgentMapType = Map<string, string[]>;
export type AllQueueAgentMapEvent = BaseAgentEvent &
    Readonly<{
        type: "AllQueueAgentMapEvent";
        data: queueAgentMapType;
    }>;

export type MembersCountEvent =
    Readonly<{
        type: "MembersCountEvent";
        agent_party: string;
        queue: string;
        in_queue_num: string;
        date_time: string;
    }>;

export type AgentOnCallEvent = BaseAgentEvent &
    Readonly<{
        type: "AgentOnCallEvent";
        other_party: string;
        state: string;
        cause: string;
    }>;

export type RecvDtmfEvent =
    Readonly<{
        type: "RecvDtmfEvent";
        uuid: string;
        opt: string;
        allow: ReadonlyArray<AllowOperation>;
        date_time: string;
    }>;

export type StartVideoPullEvent =
    Readonly<{
        type: "StartVideoPullEvent";
        device: string;
        video_stream: string;
        date_time: string;
    }>;

export type StopVideoPullEvent =
    Readonly<{
        type: "StopVideoPullEvent";
        device: string;
        date_time: string;
    }>;

export type PublishStreamPrepareEvent =
    Readonly<{
        type: "PublichStreamPrepareEvent";
        stream_path: string;
    }>;

export type EnumExtractInfo = "地址" | "烟雾类型" | "";

export type SpeechContent = Readonly<{
    id: string;
    label: "customer" | "agent";
    content: string;
    extract_type: EnumExtractInfo; //提取的类型
    extract_content: string; //提取的内容
}>;

export type AgentDetectSpeechEvent = BaseAgentEvent &
    Readonly<{
        type: "DetectSpeech";
        data: SpeechContent;
    }>;

export type AgentErrorEvent =
    Readonly<{
        type: "AgentErrorEvent";
        code: number;
        message: string;
    }>;

export type StartVideoPushEvent = BaseAgentEvent &
    Readonly<{
        type: "StartVideoPushEvent",
        push_device: string;
        video_stream: string;
    }>;

export type StopVideoPushEvent = BaseAgentEvent &
    Readonly<{
        type: "StopVideoPushEvent",
        push_device: string;
    }>;

export type VoiceChangeEvent = BaseAgentEvent &
    Readonly<{
        type: "VoiceChangeEvent";
        info: string;
    }>;

export type AgentEvent = AgentLoginFailedEvent
    | AgentNullEvent
    | AgentLoginSuccessEvent
    | AgentLogoutEvent
    | AgentStatusChangeEvent
    | AgentCallRingingEvent
    | AgentCallRingBackEvent
    | AgentCallHangupEvent
    | AgentCallHoldEvent
    | AgentCallUnHoldEvent
    | AgentCallMuteEvent
    | AgentCallUnMuteEvent
    | AgentErrorEvent
    | AgentOnCallEvent
    | AgentCallConsultEvent
    | OtherPartyCanceledEvent
    | ThisPartyAnsweredEvent
    | MonitorForceActionAnsweredEvent
    | RecvDtmfEvent
    | MembersCountEvent
    | StartVideoPullEvent
    | StopVideoPullEvent
    | AgentDetectSpeechEvent
    | DetectSpeechEvent
    | OtherPartyAnsweredEvent
    | StartVideoPushEvent
    | StopVideoPushEvent
    | PublishStreamPrepareEvent
    | VoiceChangeEvent
    | AllQueueListEvent
    | AllQueueAgentMapEvent
    | ErrorEvent;

let dummy_agent_event: AgentEvent;
export type EnumAgentEventType = typeof dummy_agent_event.type;

export type AgentEventListenerType = (e: AgentEvent) => void;

export const wsCloseCause = (code: number) => {
    switch (code) {
        case WS_CLOSE_NORMAL: return "正常登出";
        case WS_CLOSE_GOING_AWAY: return "浏览器页面关闭或跳转，请重新登录";
        case WS_CLOSE_PROTOCOL_ERROR: return "因协议错误无法连接服务器，请通知系统管理员";
        case WS_CLOSE_UNSUPPORTED: return "因数据类型不支持无法连接服务器，请通知系统管理员";
        case WS_CLOSE_NO_STATUS: return "因未知原因连接已断连，请重新登录";
        case WS_CLOSE_ABNORMAL: return "连接非正常关闭，请重新登录";
        case WS_CLOSE_TOO_LARGE: return "因数据帧过大连接已断连，请重新登录并通知系统管理员";
        case WS_CLOSE_DUPLICATE: return "浏览器重复发起连接，请登出后重新登录";
        case WS_CLOSE_SAME_AGENT: return "同工号在别处登录，本连接被断连";
        case WS_CLOSE_SAME_DEVICE: return "同分机在别处登录，本连接被断连";
        case WS_CLOSE_BY_UNREG: return "SIP话机未注册";
        case WS_CLOSE_BY_MONITOR: return "已被班长席强制退出";
        default: `WebSocket关闭代码: ${code}`;
    }
}

export const errorCause = (code: string) => {
    switch (code) {
        case "1001": return "分机尚未登录,请先在SIP话机上登录,或因配置原因请检查配置项是否有误。";
        case "1002": return "客服工号格式错误, 请检查SDK版本或将问题反馈给技术人员。";
        default: `未知错误`
    }
}

// https://freeswitch.org/confluence/display/FREESWITCH/Hangup+Cause+Code+Table
export const fsHangupCause = (cause: string): string => {
    switch (cause) {
        case "NONE": return "未说明";
        case "UNALLOCATED_NUMBER": return "被叫号码尚未启动, 请检查号码是否正确";
        case "NO_ROUTE_TRANSIT_NET": return "路由的传输网络不存在, 请联系系统管理员";
        case "NO_ROUTE_DESTINATION": return "电信网络无法接通被叫号码, 请检查号码是否正确";
        case "CHANNEL_UNACCEPTABLE": return "通道不接受呼叫, 请联系系统管理员";
        case "CALL_AWARDED_DELIVERED": return "呼入电话被重复创建, 请挂机";
        case "NORMAL_CLEARING": return "正常挂机";
        case "USER_BUSY": return "对方正在通话中, 请稍后再试";
        case "NO_USER_RESPONSE": return "对方无应答, 请稍后再试";
        case "NO_ANSWER": return "对方无应答, 请稍后再试";
        case "SUBSCRIBER_ABSENT": return "对方已关机, 请稍后再试";
        case "CALL_REJECTED": return "对方或电信网络已拒接, 请稍后再试";
        case "NUMBER_CHANGED": return "对方号码已更改, 请获取新号码再试";
        case "REDIRECTION_TO_NEW_DESTINATION": return "将转接到新号码";
        case "EXCHANGE_ROUTING_ERROR": return "电信网络路由错误, 请检查号码是否正确";
        case "DESTINATION_OUT_OF_ORDER": return "对方设备故障或不在线, 请稍后再试";
        case "INVALID_NUMBER_FORMAT": return "被叫号码格式错误";
        case "FACILITY_REJECTED": return "电信网络无法提供附加服务, 请稍后再试";
        case "RESPONSE_TO_STATUS_ENQUIRY": return "查询STATUS错误, 请联系系统管理员";
        case "NORMAL_UNSPECIFIED": return "未指明的电信网络故障";
        case "NORMAL_CIRCUIT_CONGESTION": return "线路忙, 请稍后再试";
        case "NETWORK_OUT_OF_ORDER": return "电信网络故障, 请等待较长时间后再重试";
        case "NORMAL_TEMPORARY_FAILURE": return "电信网络临时故障, 请再试";
        case "SWITCH_CONGESTION": return "电信网络忙碌, 请稍后再试";
        case "ACCESS_INFO_DISCARDED": return "电信网络无法向对端发送访问信息, 请检查号码是否正确";
        case "REQUESTED_CHAN_UNAVAIL": return "通道无法使用, 请稍后再试";
        case "PRE_EMPTED": return "错误PRE_EMPTED, 请稍后再试";
        case "FACILITY_NOT_SUBSCRIBED": return "用户无权访问附加服务, 请联系系统管理员";
        case "OUTGOING_CALL_BARRED": return "外呼电话被禁用, 请联系系统管理员";
        case "INCOMING_CALL_BARRED": return "呼入电话被禁用, 请联系系统管理员";
        case "BEARERCAPABILITY_NOTAUTH": return "用户无权使用相关功能, 请联系系统管理员";
        case "BEARERCAPABILITY_NOTAVAIL": return "用户请求的功能暂无法使用, 请联系系统管理员";
        case "SERVICE_UNAVAILABLE": return "服务暂无法使用, 请联系系统管理员";
        case "BEARERCAPABILITY_NOTIMPL": return "用户请求的功能尚不支持, 请联系系统管理员";
        case "CHAN_NOT_IMPLEMENTED": return "用户请求的通道类型尚不支持, 请联系系统管理员";
        case "FACILITY_NOT_IMPLEMENTED": return "用户请求的附加服务尚不支持, 请联系系统管理员";
        case "SERVICE_NOT_IMPLEMENTED": return "服务尚不支持, 请联系系统管理员";
        case "INVALID_CALL_REFERENCE": return "无效的呼叫引用, 请挂机后重试";
        case "INCOMPATIBLE_DESTINATION": return "对端设备不兼容";
        case "INVALID_MSG_UNSPECIFIED": return "无效的消息, 请挂机后重试";
        case "MANDATORY_IE_MISSING": return "缺少必需的参数, 请挂机后重试";
        case "MESSAGE_TYPE_NONEXIST": return "消息类型不存在, 请挂机后重试";
        case "WRONG_MESSAGE": return "消息类型不兼容、不存在或未实现, 请挂机后重试";
        case "IE_NONEXIST": return "参数不存在或未实现, 请挂机后重试";
        case "INVALID_IE_CONTENTS": return "无效的参数值, 请挂机后重试";
        case "WRONG_CALL_STATE": return "错误的呼叫状态, 请挂机后重试";
        case "RECOVERY_ON_TIMER_EXPIRE": return "定时器超时错误, 请挂机后重试";
        case "MANDATORY_IE_LENGTH_ERROR": return "参数值长度错误, 请挂机后重试";
        case "PROTOCOL_ERROR": return "协议错误, 请挂机后重试";
        case "INTERWORKING": return "交互电话已挂机";
        case "SUCCESS": return "成功";
        case "ORIGINATOR_CANCEL": return "发起方已取消";
        case "CRASH": return "系统崩溃, 请稍后再试";
        case "SYSTEM_SHUTDOWN": return "系统已关闭, 请稍后再试";
        case "LOSE_RACE": return "LOSE RACE, 请挂机后重试";
        case "MANAGER_REQUEST": return "由开发接口发送挂机命令挂机";
        case "BLIND_TRANSFER": return "电话已盲转";
        case "ATTENDED_TRANSFER": return "电话已转移";
        case "ALLOTTED_TIMEOUT": return "电话过长时间未接听";
        case "USER_CHALLENGE": return "USER CHALLENGE";
        case "MEDIA_TIMEOUT": return "媒体数据超时, 请挂机后重试";
        case "PICKED_OFF": return "电话被代接";
        case "USER_NOT_REGISTERED": return "用户SIP电话尚未注册";
        case "PROGRESS_TIMEOUT": return "PROGRESS超时, 请挂机后重试";
        case "INVALID_GATEWAY": return "无效的网关, 请联系系统管理员";
        case "GATEWAY_DOWN": return "网关不在线, 请联系系统管理员";
        case "INVALID_URL": return "无效的URL";
        case "INVALID_PROFILE": return "无效的PROFILE, 请联系系统管理员";
        case "NO_PICKUP": return "错误NO PICKUP, 请挂机后重试";
        case "SRTP_READ_ERROR": return "SRTP读发生错误, 请挂机后重试";
        case "BOWOUT": return "错误BOWOUT, 请挂机后重试";
        case "BUSY_EVERYWHERE": return "错误BUSY_EVERYWHERE, 请挂机后重试";
        case "DECLINE": return "错误DECLINE, 请挂机后重试";
        case "DOES_NOT_EXIST_ANYWHERE": return "错误DOES_NOT_EXIST_ANYWHERE, 请挂机后重试";
        case "NOT_ACCEPTABLE": return "错误NOT_ACCEPTABLE, 请挂机后重试";
        case "UNWANTED": return "错误UNWANTED, 请挂机后重试";
        case "NO_IDENTITY": return "错误NO_IDENTITY, 请挂机后重试";
        case "BAD_IDENTITY_INFO": return "错误BAD_IDENTITY_INFO, 请挂机后重试";
        case "UNSUPPORTED_CERTIFICATE": return "错误UNSUPPORTED_CERTIFICATE, 请挂机后重试";
        case "INVALID_IDENTITY": return "错误INVALID_IDENTITY, 请挂机后重试";
        case "STALE_DATE": return "错误STALE_DATE, 请挂机后重试"
        default: return `错误${cause}, 请挂机后重试`;
    }
}
