export const isExtensionNumber = (n: string): boolean => {
    const extension = n.split("@")[0];
    return extension.length <= 6 && !isNaN(parseFloat(extension)) && isFinite(extension as any);
}

export const between = (n: string, begin: string, end: string) => {
    if (n.length !== begin.length || n.length !== end.length) return false;
    return begin <= n && n <= end;
}

export const addHostExtension = (agentId: string, host: string): string => {
    if (!agentId) {
        return "";
    }
    if (isFullExtension(agentId) || isExternalNumber(agentId)) {
        return agentId;
    } else {
        return agentId + "@" + host;
    }
}

export const isFullExtension = (n: string) => n.includes("@") && n.split("@").length === 2;

export const isExternalNumber = (n: string): boolean => !!n && !isExtensionNumber(n);

export const isValidNumber = (n: string) => !!n && n.match(/^\d{4,16}$/) !== null;