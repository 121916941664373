import { getAgentEventListener } from "./agent_event_listener";
import {
    allowOperation,
    errorMessage,
    getAllowByStatus,
    getAllowOperationKeyByStatus,
} from "../types";
import { closeAccessClient, getAccessClient } from "../web_socket_client";
import {
    EnumAgentStatus,
    AGENT_NULL_EVENT,
    AgentEvent,
    AgentLoginFailedEvent,
    AgentLoginSuccessEvent,
    AgentStatusChangeEvent,
    WS_CLOSE_SAME_AGENT,
    AgentCallRingingEvent,
    AgentCallHangupEvent,
    AgentCallHoldEvent,
    AgentCallUnHoldEvent,
    AgentCallConsultEvent,
    AllowOperation,
    OtherPartyCanceledEvent,
    RecvDtmfEvent,
    WS_CLOSE_BY_MONITOR,
    STATUS_LOGOUT,
    MonitorForceActionAnsweredEvent,
    WS_CLOSE_BY_UNREG,
    MembersCountEvent,
    WS_CLOSE_SAME_DEVICE,
    STATUS_INCALL_READY,
    STATUS_READY,
    errorCause,
    AgentOnCallEvent,
    AgentCallMuteEvent,
    AgentCallUnMuteEvent,
    StartVideoPullEvent,
    StopVideoPullEvent,
    DetectSpeechEvent,
    AgentErrorEvent,
    StartVideoPushEvent,
    StopVideoPushEvent,
    STATUS_DIALING,
    STATUS_RINGING,
    AgentCallRingBackEvent,
    ThisPartyAnsweredEvent,
    OtherPartyAnsweredEvent,
    STATUS_TALKING,
    VoiceChangeEvent,
    AllQueueListEvent,
    AllQueueAgentMapEvent,
    WS_CLOSE_NORMAL,
} from "../types/types_code";
import { createSession, currentSession, destroySession } from "../call_session/sessions";
import { isExternalNumber } from "../types/utils";
import { AgentConfig } from "../configuration";

export const processEvent = async (
    simple: Record<string, any>
): Promise<void> => {
    if (Object.keys(simple).length < 2) return;
    let ae: AgentEvent = AGENT_NULL_EVENT;
    if (simple["Event"] === "LoginFailedEvent") {
        const temp: AgentLoginFailedEvent = {
            type: "LoginFailedEvent",
            agent_id: getAccessClient().getAgentId(),
            device: simple["Device"],
            cause: errorCause(simple["Reason"]),
            date_time: simple["DateTime"]
        };
        ae = temp;
        closeAccessClient();
    } else if (simple["Event"] === "LoginSuccessEvent") {
        const temp: AgentLoginSuccessEvent = {
            type: "LoginSuccessEvent",
            agent_id: getAccessClient().getAgentId(),
            device: simple["Device"],
            allow: allowOperation("AGENT_READY"),
            date_time: simple["DateTime"]
        };
        getAccessClient().setMyAllowOperate("AGENT_READY");
        //设置登录状态
        const status = AgentConfig.isAfterCall ? STATUS_READY : STATUS_INCALL_READY;
        getAccessClient().sendSetAgentStatusCommand(status);
        ae = temp;
    } else if (simple["Event"] === "DuplicateConnEvent") {
        if (simple["Reason"] === "device") {
            getAccessClient().disconnect(WS_CLOSE_SAME_DEVICE);
        } else {
            getAccessClient().disconnect(WS_CLOSE_SAME_AGENT);
        }
        return;
    } else if (simple["Event"] === "PhoneUnregEvent") {
        getAccessClient().disconnect(WS_CLOSE_BY_UNREG);
        return;
    } else if (simple["Event"] === "AgentStatusEvent") {
        const status: EnumAgentStatus = `${simple["Status"]}` as any;
        const temp: AgentStatusChangeEvent = {
            type: "AgentStatusEvent",
            agent_id: getAccessClient().getAgentId(),
            device: simple["Device"],
            isMntForce: simple['Device'] === "mnt" ? true : false,
            status: status === STATUS_INCALL_READY ? STATUS_READY : status,
            allow: getAllowByStatus(status),
            date_time: simple["DateTime"]
        }
        if (status === STATUS_LOGOUT) {
            if (simple['Device'] === "mnt") {
                getAccessClient().disconnect(WS_CLOSE_BY_MONITOR);
            } else {
                getAccessClient().disconnect(WS_CLOSE_NORMAL);
            }
            return;
        } else {
            getAccessClient().setMyAllowOperate(getAllowOperationKeyByStatus(status));
            ae = temp;
        }
    } else if (simple["Event"] === "RingBackEvent") {//外呼振铃事件
        let temp: AgentCallRingBackEvent = {
            type: "RingBackEvent",
            agent_id: getAccessClient().getAgentId(),
            device: simple["Device"],
            agent_party: simple["Caller"],
            other_party: simple["Called"],
            is_consult: simple["CallType"] === 'consult' ? true : false,
            uuid: simple["CallType"] === 'consult' ? simple["OtherLegUUID"] : simple["UUID"],
            other_party_uuid: simple["CallType"] === 'consult' ? simple["UUID"] : simple["OtherLegUUID"],
            is_inbound: false,
            status: STATUS_DIALING,
            date_time: simple["DateTime"],
            allow: allowOperation("S_RINGING"),
        };
        getAccessClient().setMyAllowOperate("S_RINGING");
        const session = currentSession();
        if (session) {
            session.updateCallerRole(true);
            //注意：磋商时获取的uuid为被磋商者的uuid，非首agent的uuid
            session.createConsult(simple["Called"], simple["CallType"] === 'consult' ? simple["OtherLegUUID"] : simple["UUID"]);
            (temp as any).allow = allowOperation('S_HOLD_DIALING');
            getAccessClient().setMyAllowOperate("S_HOLD_DIALING");
        } else {
            createSession(temp);//记录会话
        }
        ae = temp;
    } else if (simple["Event"] === "RingingEvent") {//被叫振铃事件
        //Ringing两种情况：两腿uuid都在，说明是磋商进来，如果只有一腿，就说明是用户首呼
        const temp: AgentCallRingingEvent = {
            type: "RingingEvent",
            agent_id: getAccessClient().getAgentId(),
            device: simple["Device"],
            agent_party: simple["Called"],
            other_party: simple["Caller"],
            uuid: simple["UUID"],
            // is_consult: false,
            is_inbound: true,
            status: STATUS_RINGING,
            other_party_uuid: simple["OtherLegUUID"],
            allow: allowOperation("S_RINGING"),
            user_data: simple["UserData"] ?? "",
            date_time: simple["DateTime"]
        }
        getAccessClient().setMyAllowOperate("S_RINGING");
        const session = createSession(temp);//记录会话
        session.updateCallerRole(false);
        if (simple["OtherLegUUID"]) {
            session.updateOtherParty(simple["Caller"], simple["OtherLegUUID"]);
        }
        ae = temp;
    } else if (simple["Event"] === "HangupEvent") {//挂机事件
        const session = currentSession();
        if (session == null) {
            return;
        }
        const temp: AgentCallHangupEvent = {
            type: "HangupEvent",
            agent_id: getAccessClient().getAgentId(),
            device: simple["Device"],
            other_party: simple["OtherParty"],
            uuid: simple["UUID"],
            allow: allowOperation("S_HANGUP"),//可在此插入话务前置
            user_data: simple["UserData"] ?? "",
            date_time: simple["DateTime"]
        }
        destroySession(simple["UUID"], session.is_inbound);
        getAccessClient().mntForceDest = "";
        getAccessClient().setMyAllowOperate("S_HANGUP");
        ae = temp;
    } else if (simple["Event"] === "ThisPartyAnsweredEvent") {
        if (simple["UUID"] === simple["OtherLegUUID"]) {
            return;
        }
        const session = currentSession();
        if (session == null) {
            return;
        }
        if (simple["OtherParty"] === "0000000000") {
            const temp: MonitorForceActionAnsweredEvent = {
                type: "MonitorForceActionAnsweredEvent",
                agent_id: getAccessClient().getAgentId(),
                device: simple["Device"],
                other_device: getAccessClient().mntForceDest,
                force_type: getAccessClient().mntForceBehaviour,
                uuid: simple["UUID"],
                status: STATUS_TALKING,
                video_stream: simple["VideoPullUrl"],
                allow: allowOperation("MONITOR_SNEAK"),//可在此插入话务前置
                date_time: simple["DateTime"]
            }
            getAccessClient().mntForceBehaviour = "";
            getAccessClient().setMyAllowOperate("MONITOR_SNEAK");
            ae = temp;
        } else {
            const temp: ThisPartyAnsweredEvent = {
                type: "ThisPartyAnsweredEvent",
                agent_id: getAccessClient().getAgentId(),
                device: simple["Device"],
                uuid: simple["UUID"],
                other_party: simple["OtherParty"],
                other_party_uuid: simple["OtherLegUUID"],
                is_inbound: true,
                status: STATUS_TALKING,
                allow: allowOperation("S_TALKING"),
                video_stream: simple["VideoPullUrl"],
                user_data: simple["UserData"] ?? "",
                date_time: simple["DateTime"]
            }
            getAccessClient().setMyAllowOperate("S_TALKING");
            session.updateCallerRource(simple["CallerSource"]);
            if (session.is_consult) {//outbound
                if (simple["OtherParty"].includes(session.consult_party) || session.consult_party.includes(simple["OtherParty"])) {//TODO 摒弃相等判断，可能包含前缀与前记录不符，后改为包含判断。若有需要，则要进行号码的正则去除多余前缀
                    (temp as any).allow = allowOperation("S_HOLD_CONSULT");
                    getAccessClient().setMyAllowOperate("S_HOLD_CONSULT");
                }
            } else {
                if (!session.isCaller && !isExternalNumber(simple["OtherParty"])) {
                    // temp.allow = allowOperation("S_INSIDE_DIALING");
                    (temp as any).allow = allowOperation("S_TALKING");
                    getAccessClient().setMyAllowOperate("S_TALKING");
                }
                if (!session.other_party_uuid) {
                    session.updateOtherParty(simple["OtherParty"], simple["OtherLegUUID"]);
                }
            }
            if (AgentConfig.isDetectSpeech) {
                if (simple["UUID"]) {
                    getAccessClient().sendStartDetectSpeechCommand(simple["UUID"]);
                }
                if (simple["OtherLegUUID"]) {
                    getAccessClient().sendStartDetectSpeechCommand(simple["OtherLegUUID"]);
                }
            }
            ae = temp;
        }
    } else if (simple["Event"] === "OtherPartyAnsweredEvent") {
        if (simple["UUID"] === simple["OtherLegUUID"]) {
            return;
        }
        const session = currentSession();
        if (session == null) {
            return;
        }
        const temp: OtherPartyAnsweredEvent = {
            type: "OtherPartyAnsweredEvent",
            agent_id: getAccessClient().getAgentId(),
            device: simple["Device"],
            uuid: simple["UUID"],
            other_party: simple["OtherParty"],
            other_party_uuid: simple["OtherLegUUID"],
            is_inbound: false,
            status: STATUS_TALKING,
            allow: allowOperation("S_TALKING"),
            video_stream: simple["VideoPullUrl"],
            user_data: simple["UserData"] ?? "",
            date_time: simple["DateTime"]
        }
        getAccessClient().setMyAllowOperate("S_TALKING");
        session.updateCallerRource(simple["CallerSource"]);

        if (session.is_consult) {//outbound
            if (simple["OtherParty"].includes(session.consult_party) || session.consult_party.includes(simple["OtherParty"])) {//TODO 摒弃相等判断，可能包含前缀与前记录不符，后改为包含判断。若有需要，则要进行号码的正则去除多余前缀
                (temp as any).allow = allowOperation("S_HOLD_CONSULT");
                getAccessClient().setMyAllowOperate("S_HOLD_CONSULT");
            }
        } else {
            if (!session.isCaller && !isExternalNumber(simple["OtherParty"])) {
                (temp as any).allow = allowOperation("S_INSIDE_DIALING");
                getAccessClient().setMyAllowOperate("S_INSIDE_DIALING");
            }
            if (!session.other_party_uuid) {
                session.updateOtherParty(simple["OtherParty"], simple["OtherLegUUID"]);
            }
        }
        if (AgentConfig.isDetectSpeech) {
            if (simple["UUID"]) {
                getAccessClient().sendStartDetectSpeechCommand(simple["UUID"]);
            }
            if (simple["OtherLegUUID"]) {
                getAccessClient().sendStartDetectSpeechCommand(simple["OtherLegUUID"]);
            }
        }
        ae = temp;
    } else if (simple["Event"] === "HoldEvent") {
        const temp: AgentCallHoldEvent = {
            type: "HoldEvent",
            agent_id: getAccessClient().getAgentId(),
            device: simple["Device"],
            uuid: simple["UUID"],
            allow: allowOperation("S_HOLD"),
            date_time: simple["DateTime"]
        };
        getAccessClient().setMyAllowOperate("S_HOLD");
        ae = temp;
    } else if (simple["Event"] === "UnholdEvent") {
        const temp: AgentCallUnHoldEvent = {
            type: "UnholdEvent",
            agent_id: getAccessClient().getAgentId(),
            device: simple["Device"],
            uuid: simple["UUID"],
            allow: allowOperation("S_TALKING"),
            date_time: simple["DateTime"]
        };
        getAccessClient().setMyAllowOperate("S_TALKING");
        ae = temp;
    } else if (simple["Event"] === "MuteEvent") {
        const temp: AgentCallMuteEvent = {
            type: "MuteEvent",
            agent_id: getAccessClient().getAgentId(),
            device: simple["Device"],
            uuid: simple["UUID"],
            allow: allowOperation("S_MUTE"),
            date_time: simple["DateTime"]
        };
        getAccessClient().setMyAllowOperate("S_MUTE");
        ae = temp;
    } else if (simple["Event"] === "UnMuteEvent") {
        const temp: AgentCallUnMuteEvent = {
            type: "UnMuteEvent",
            agent_id: getAccessClient().getAgentId(),
            device: simple["Device"],
            uuid: simple["UUID"],
            allow: allowOperation("S_TALKING"),
            date_time: simple["DateTime"]
        };
        getAccessClient().setMyAllowOperate("S_TALKING");
        ae = temp;
    } else if (simple["Event"] === "ConsultEvent") {
        const session = currentSession();
        if (session == null) {
            return;
        }
        let allow: AllowOperation[] = [];
        if (simple["Reason"] === "HangUp") {
            session.destroyConsult();
            allow = [].concat(allowOperation("S_TALKING"));
            getAccessClient().setMyAllowOperate("S_TALKING");
            //如果收到对端磋商挂断，并且不是主叫方，那么
        } else {//Success/Fail 不需要设置，后面会紧跟talking

        }
        const temp: AgentCallConsultEvent = {
            type: "ConsultEvent",
            device: simple["Device"],
            cause: simple["Reason"],
            status: STATUS_TALKING,
            allow: allow,
            date_time: simple["DateTime"]
        };
        ae = temp;
    } else if (simple["Event"] === "OtherPartyCanceledEvent") {
        const session = currentSession();
        if (session == null) {
            return;
        }
        if (session.consult_party === ""
            || !session.consult_party.includes(simple["CanceledParty"]) && !simple["CanceledParty"].includes(session.consult_party)) {
            return;
        }
        session.destroyConsult();
        const temp: OtherPartyCanceledEvent = {
            type: "OtherPartyCanceledEvent",
            device: simple["Device"],
            cause: simple["Reason"],
            status: STATUS_TALKING,
            allow: allowOperation("S_TALKING"),
            date_time: simple["DateTime"]
        };
        getAccessClient().setMyAllowOperate("S_TALKING");
        ae = temp;
    } else if (simple["Event"] === "RecvDtmfEvent") {
        const session = currentSession();
        if (session == null) {
            return;
        }
        if (simple["Opt"] === "0") {
            const temp: RecvDtmfEvent = {
                type: "RecvDtmfEvent",
                uuid: simple["UUID"],
                opt: `${simple["Opt"]}`,
                allow: allowOperation("S_THREE_PARTIES"),
                date_time: simple["DateTime"]
            };
            getAccessClient().setMyAllowOperate("S_THREE_PARTIES");
            ae = temp;
        } else {
            return;
        }
    } else if (simple["Event"] === "MembersCountEvent") {
        const temp: MembersCountEvent = {
            type: "MembersCountEvent",
            agent_party: simple["AgentId"],
            queue: simple["Queue"],
            in_queue_num: `${simple["InQueueNum"]}`,
            date_time: simple["DateTime"]
        };
        ae = temp;
    } else if (simple["Event"] === "AgentOnCallEvent") {
        const temp: AgentOnCallEvent = {
            type: "AgentOnCallEvent",
            agent_id: getAccessClient().getAgentId(),
            device: getAccessClient().getDevice(),
            other_party: simple["Called"],
            state: simple["State"],
            cause: `${simple["Called"]}分机所属客服正在通话中`,
            date_time: simple["DateTime"]
        }
        ae = temp;
    } else if (simple["Event"] === "StartVideoPullEvent") {
        const temp: StartVideoPullEvent = {
            type: "StartVideoPullEvent",
            device: simple["Device"],
            video_stream: simple["VideoPullUrl"],
            date_time: simple["DateTime"]
        }
        ae = temp;
    } else if (simple["Event"] === "StopVideoPullEvent") {
        const temp: StopVideoPullEvent = {
            type: "StopVideoPullEvent",
            device: simple["Device"],
            date_time: simple["DateTime"]
        }
        ae = temp;
    } else if (simple["Event"] === "DetectSpeechEvent") {
        const temp: DetectSpeechEvent = {
            type: "DetectSpeechEvent",
            agent_id: getAccessClient().getAgentId(),
            device: simple["Device"],
            speaker: simple["Speaker"],
            content: simple["Content"],
            date_time: simple["DateTime"]
        }
        ae = temp;
    } else if (simple["Event"] === "RequestErrorEvent") {
        const errorCode = parseInt(simple["ErrorCode"]);
        const temp: AgentErrorEvent = {
            type: "AgentErrorEvent",
            code: errorCode,
            message: errorMessage(errorCode)
        };
        ae = temp;
    } else if (simple["Event"] === "StartVideoPushEvent") {
        const temp: StartVideoPushEvent = {
            type: "StartVideoPushEvent",
            agent_id: getAccessClient().getAgentId(),
            device: getAccessClient().getDevice(),
            push_device: simple["PushDevice"],
            video_stream: simple["VideoPullUrl"],
            date_time: simple["DateTime"]
        };
        ae = temp;
    } else if (simple["Event"] === "StopVideoPushEvent") {
        const temp: StopVideoPushEvent = {
            type: "StopVideoPushEvent",
            agent_id: getAccessClient().getAgentId(),
            device: getAccessClient().getDevice(),
            push_device: simple["PushDevice"],
            date_time: simple["DateTime"]
        };
        ae = temp;
    } else if (simple["Event"] === "VoiceChangeEvent") {
        const temp: VoiceChangeEvent = {
            type: "VoiceChangeEvent",
            agent_id: getAccessClient().getAgentId(),
            device: simple["Device"],
            info: simple["Info"],
            date_time: simple["DateTime"]
        };
        ae = temp;
    } else if (simple["Event"] === "AllQueueListEvent") {
        const temp: AllQueueListEvent = {
            type: "AllQueueListEvent",
            agent_id: getAccessClient().getAgentId(),
            device: simple["Device"],
            list: simple["List"],
            date_time: simple["DateTime"]
        };
        ae = temp;
    } else if (simple["Event"] === "AllQueueAgentMapEvent") {
        const temp: AllQueueAgentMapEvent = {
            type: "AllQueueAgentMapEvent",
            agent_id: getAccessClient().getAgentId(),
            device: simple["Device"],
            data: simple["Data"],
            date_time: simple["DateTime"]
        };
        ae = temp;
    }
    getAgentEventListener(ae.type)(ae);
};