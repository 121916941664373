import { AgentConfig } from "../configuration";
import { addClientError } from "./client_error_reporter";

const debug = (message: string) => console.log(message);

export const warn = (message: string) => {
    addClientError(message);
    console.warn(message);
}

export const error = (message: string) => {
    addClientError(message);
    console.error(message);
}

export const traceCallControl = (message: string) => {
    if (AgentConfig.traceMsgDebug) {
        debug(message);
    }
};

export const traceWebSocket = (message: string) => {
    debug(message);
};

export const traceSimpleEvent = (message: string) => {
    if (AgentConfig.traceMsgDebug) {
        debug(message);
    }
};

export const assert = (condition: boolean, message: string) => {
    if (!condition) {
        addClientError(message);
    }
    console.assert(condition, message);
}
