import { assert } from "../types";

export class Session {
    constructor(
        public readonly agent_party: string,
        public readonly agent_party_uuid: string,
        public readonly other_party: string,
        public readonly is_inbound: boolean
    ) {
        assert(!!agent_party, "Session agent_party为空");
        assert(!!agent_party_uuid, `Session ${agent_party} agent_party_uuid为空`);
        assert(agent_party !== other_party, `Session agent_party ${agent_party} === other_party`);
        assert(!!other_party, `Session ${agent_party} other_party为空`);
    }

    get id() {
        return this.agent_party_uuid;
    }
    get other_party_uuid() {
        return this._other_party_uuid;
    }

    get consult_party() {
        return !this.is_consult ? "" : this._consult_party;
    }

    get consult_party_uuid() {
        return !this.is_consult ? "" : this._consult_party_uuid;
    }

    get isCaller() {
        return this._is_caller;
    }

    get callerSource() {
        return this._caller_source;
    }

    updateCallerRource = (callerSource: string) => {
        if (callerSource) {
            this._caller_source = callerSource;
        } else {
            this._caller_source = "mod_callcenter"
        }
    }

    updateCallerRole = (is_caller: boolean) => {
        this._is_caller = is_caller;
    }

    destroyConsult = () => {
        this.is_consult = false;
        this._consult_party = "";
        this._consult_party_uuid = "";
    };

    hasBothParties = (): boolean =>
        !!this.agent_party && !!this.agent_party_uuid && !!this.other_party && !!this.other_party_uuid;

    createConsult = (party: string, call_uuid = "") => {
        assert(!!party, `createConsult ${this.agent_party} party为空`);
        assert(party !== this.agent_party, `createConsult ${this.agent_party} party === this.agent_party`);
        assert(call_uuid !== this.agent_party_uuid, `createConsult ${this.agent_party} call_uuid === this.agent_party_uuid`);
        assert(call_uuid !== this.other_party_uuid, `createConsult ${this.agent_party} call_uuid === this.other_party_uuid`);
        this._consult_party = party;
        this._consult_party_uuid = call_uuid;
        this.is_consult = true;
    };

    updateOtherParty = (other_party: string, other_party_uuid: string): void => {
        assert(other_party !== this.agent_party, `updateOtherParty ${this.agent_party} other_party === this.agent_party`);
        assert(
            other_party.includes(this.other_party) || this.other_party.includes(other_party),
            `updateOtherParty ${this.agent_party} other_party ${other_party} !== this.other_party ${this.other_party}`
        );
        assert(
            other_party_uuid !== this.agent_party_uuid,
            `updateOtherParty ${this.agent_party} other_party_uuid === this.agent_party_uuid`
        );
        if (this.hasBothParties()) return;
        if (this.other_party_uuid === "" && !!other_party_uuid) {
            this._other_party_uuid = other_party_uuid;
        }
    };
    public is_consult = false;
    private _consult_party = "";
    private _consult_party_uuid = "";
    private _other_party_uuid = "";
    private _is_caller = true;
    private _caller_source = "";
}

