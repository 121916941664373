import { getAgentEventListener } from "./event_handler";
import { traceCallControl } from "./types";

let elementMap = new Map<string, RTCPeerConnection>();

// 播放客户视频
// http://172.16.20.181:8080/webrtc/play/live/obs_331
export const show_customer_view = (elementId, href) => {
    if (elementMap.has(elementId)) {
        close_customer_view(elementId);
    }

    const pc = new RTCPeerConnection();

    pc.onconnectionstatechange = (e) => {
        traceCallControl(`onconnectionstatechange ${pc.connectionState}`);
        traceCallControl(`onconnectionstatechange ${JSON.stringify(e)}`);
    };

    pc.ontrack = (e) => {
        if (e.streams.length === 0) return;
        try {
            (document.getElementById(elementId) as any).srcObject = e.streams[0];
            (document.getElementById(elementId) as any).play();
        } catch (error) {
            getAgentEventListener("VideoStreamErrorEvent" as any)(error);
            // console.log("视频流存在错误!请检查网络或设备是否支持视频方案");
        }
    };

    pc.oniceconnectionstatechange = () => {
        traceCallControl(`oniceconnectionstatechange ${pc.iceConnectionState}`);
    };
    pc.onicecandidate = (e) => {
        traceCallControl(`onicecandidate ${e.candidate}`);
    };
    pc.addTransceiver("video", { direction: "recvonly" });
    pc.addTransceiver("audio", { direction: "recvonly" });

    elementMap.set(elementId, pc);
    (async () => {
        try {
            const offer = await pc.createOffer();
            await pc.setLocalDescription(offer);
            const result = await fetch(href, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                redirect: "follow",
                referrerPolicy: "no-referrer",
                headers: { "Content-Type": "application/sdp" },
                body: offer.sdp,
            }).then(response => {
                if (response.ok) {
                    return response;
                } else {
                    return Promise.reject('data stream request error, status:' + response.status);
                }
            }).catch(error => { throw error });
            const remoteSdp = await result.text();
            await pc.setRemoteDescription(new RTCSessionDescription({ type: "answer", sdp: remoteSdp }),);
        } catch (error) {
            getAgentEventListener("VideoStreamErrorEvent" as any)(error);
        }
    })();
}

// 停止播放客户视频
export const close_customer_view = (elementId: string) => {
    if (elementMap.has(elementId)) {
        const pc = elementMap.get(elementId);
        pc.close();
        (document.getElementById(elementId) as any).srcObject = null;
        elementMap.delete(elementId);
    }
}
