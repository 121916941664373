import { AgentControl, CallControl } from "./api";
import { VoiceCommAgentControl, VoiceCommCallControl } from "./voicecomm";

export function createAgentControl(): AgentControl {
    return new VoiceCommAgentControl();
}

export function createCallControl(): CallControl {
    return new VoiceCommCallControl();
}
