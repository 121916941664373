import { assert } from "../types";
import { agentAPIVersion } from "../version";
import { AgentConfiguration } from "./agent_api";
import { fetchConfiguration } from "./fetch_config";

let server = "";
let mode = "development";

export const setConfigureServer = (conf: AgentConfiguration) => {
    let config = fetchConfiguration(conf);
    displayConfigure(config);
};

export const getConfigureServer = () => server;

export const setMode = (m: string) => (mode = m);

export const isDevelopmentMode = () => mode === "development";

export const isProductionMode = () => !isDevelopmentMode();

const displayConfigure = (cfg: AgentConfiguration) => {
    if (cfg.deviceType == "webphone") {
        console.log(`电话类型: webrtc-phone`);
    } else {
        console.log(`电话类型: soft-phone`);
    }
    console.log(`go版座席API版本号: ${agentAPIVersion()}`);
    console.log(`运行环境: ${isDevelopmentMode() ? "开发环境" : "生产环境"}`);
    console.log(`访问服务器地址: ${cfg.webSocketSSL ? "wss" : "ws"}://${cfg.webSocketHost}:${cfg.webSocketPort}`);
    if (cfg.deviceType != "webphone") {
        console.log(`租户地址: ${cfg.domain}`);
        console.log(`话后处理: ${cfg.isAfterCall ? "是" : "否"}`);
    }
    console.log(`语音识别翻译: ${cfg.isDetectSpeech ? "是" : "否"}`);
    console.log(`打印事件消息：${cfg.traceMsgDebug ? "是" : "否"}`);
    assert(!!cfg.webSocketHost && !!cfg.webSocketPort, `配置项: socket连接项未配置`);
    if (cfg.deviceType != "webphone") {
        console.log(`使用接听工具地址: ${cfg.answerToolUrl}`);
        assert(!!cfg.domain, `配置项: domain不允许为空`);
        assert(!!cfg.trunkNumber, `配置项: trunkNumber不允许为空`);
        assert(!!cfg.gateway, `配置项: gateway不允许为空`);
        assert(cfg.outno !== undefined, `配置项: 请传递outno字段, 空则串空串`);
    } else {
        assert(!!cfg.sipHost, `配置项: sipHost不允许为空`);
        assert(!!cfg.webrtcWssDomain, `配置项: webrtcWssDomain不允许为空`);
        assert(!!cfg.webrtcWssPort, `配置项: webrtcWssPort不允许为空`);
    }
};
