import {
    assert,
    error,
    traceWebSocket,
    getAllowByStatus,
    notifyError,
    traceCallControl,
    AllowOperationKey,
    allowOperation,
    getAllowOperationKeyByStatus,
    ERROR_EMPTY_DEVICE,
    errorMessage,
} from "./types";
import { AgentConfiguration } from "./configuration";
import { getAgentEventListener, processEvent } from "./event_handler";
import { AgentLogoutEvent, EnumAgentStatus, STATUS_INCALL_READY, STATUS_LOGOUT, STATUS_READY, WS_CLOSE_ABNORMAL, WS_CLOSE_DUPLICATE, WS_CLOSE_NORMAL, wsCloseCause } from "./types/types_code";
import { currentSession, destroySessionByWbsocket } from "./call_session";
import { addHostExtension } from "./types/utils";
const dayjs = require('dayjs');

export class AccessWebSocketClient {
    constructor(c: AgentConfiguration) {
        assert(!!c.webSocketHost, `AccessWebSocketClient参数webSocketHost为空`);
        assert(!!c.webSocketPort, `AccessWebSocketClient参数webSocketPort为空`);
        this.config = c;
        this.URL = `${c.webSocketSSL ? "wss" : "ws"}://${c.webSocketHost}:${c.webSocketPort}`;
        this.cleanup();
    }

    connect = () => {
        assert(this.ws === undefined, `connect()时发现已有WebSocket连接`);
        this.ws = new WebSocket(this.URL);
        this.ws.onmessage = this.receive;
        this.ws.onopen = (ev: Event) => {
            traceWebSocket(`WebSocket连接成功`);
            this.connectedListener();
        };
        this.ws.onerror = (ev: Event) => {
            traceWebSocket(`WebSocket错误[${ev}], 将关闭并通知UI`);
            this.disconnect(WS_CLOSE_ABNORMAL);
            this.errorListener();
        };
        this.ws.onclose = (ev: CloseEvent) => {
            traceWebSocket(`WebSocket已断连`);
            this.notifyUI(ev.code);
            this.cleanup();
            this.disconnectedListener();
            accessClient = undefined;
        };
    };

    disconnect = (code: number) => {
        // assert(WS_CLOSE_NORMAL <= code && code <= WS_CLOSE_BY_UNREG, `disconnect未知的code: ${code}`);
        if (this.isConnected()) {
            const delay = this.ws.bufferedAmount > 0 ? 200 : 0;
            setTimeout(() => this.ws.close(code), delay);
        } else if (this.isConnecting()) {
            this.ws.close(code);
        } else {
            assert(false, `disconnect(${code})时WebSocket状态是[${getStateText(this.ws)}]`);
        }
    };

    hasWebSocket = () => this.ws !== undefined;

    isThisAgent = (id: string) => (id === this.agentID) || (id === this.agentID.split("@")[0]);

    isThisDevice = (id: string) => (id === this.device) || (id === this.device.split("@")[0]);

    sendNewLoginCommand = (cmd: any): boolean => {
        this.skillGroup = cmd.Queue;
        this.agentID = cmd.AgentId;
        this.device = cmd.Device;
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    }

    sendSetAgentStatusCommand = (status: EnumAgentStatus) => {
        // const cmd = `SetStatus|${this.agentID}|${this.agentID.split('@')[0]}|${status}`;
        const cmd: any = {
            Action: "SetStatus",
            AgentId: this.agentID,
            Device: this.device,
            Status: `${status}`
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendTierOn = (peerAgentID: string, queue: string) => {
        const cmd: any = {
            Action: "TierOn",
            AgentId: addHostExtension(peerAgentID, this.config.domain),
            Queue: addHostExtension(queue, this.config.domain)
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendTierOff = (peerAgentID: string, queue: string) => {
        const cmd: any = {
            Action: "TierOff",
            AgentId: addHostExtension(peerAgentID, this.config.domain),
            Queue: addHostExtension(queue, this.config.domain)
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendQueueDetail = () => {
        const cmd: any = {
            Action: "QueueDetail",
            Device: this.device,
            Domain: this.config.domain
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    }

    sendDTMFCommand = (dtmf: string) => {
        const session = currentSession();
        if (!session) {
            return false;
        }
        const cmd: any = {
            Action: "SendDTMF",
            UUID: session.id,
            Device: this.device,
            DTMF: dtmf
        }
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    }

    // Call Control
    sendMakeVoiceCallCommand = (target: string, trunkNumber: string, gateway: string, outno: string) => {
        target = target?.trim();
        if (!target) {
            notifyError(ERROR_EMPTY_DEVICE, errorMessage(ERROR_EMPTY_DEVICE));
            return false;
        }
        trunkNumber = trunkNumber?.trim();
        gateway = gateway?.trim();
        outno = outno?.trim();
        // const cmd = `MakeCall|${this.agentID.split("@")[0]}|${target.split("@")[0]}|${config.trunkNumber}`;
        const cmd: any = {
            Action: "MakeCall",
            Device: this.device,
            Called: target,
            OriginalCaller: !!trunkNumber ? trunkNumber : this.config.trunkNumber,
            OriginalGateway: !!gateway ? gateway : this.config.gateway,
            OriginalOutno: !!outno ? outno : this.config.outno,
            Domain: this.config.domain
        }
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendHoldCallCommand = () => {
        const session = currentSession();
        if (!session) {
            return false;
        }
        // const cmd = `Hold|${session.id}`;
        const cmd: any = {
            Action: "Hold",
            Device: this.device,
            UUID: session.id
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendUnHoldCallCommand = () => {
        const session = currentSession();
        if (!session) {
            return false;
        }
        // const cmd = `UnHold|${session.id}`;
        const cmd: any = {
            Action: "UnHold",
            Device: this.device,
            UUID: session.id
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendMuteCallCommand = () => {
        const session = currentSession();
        if (!session) {
            return false;
        }
        const cmd: any = {
            Action: "Mute",
            Device: this.device,
            UUID: session.other_party_uuid
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendUnMuteCallCommand = () => {
        const session = currentSession();
        if (!session) {
            return false;
        }
        // const cmd = `UnHold|${session.id}`;
        const cmd: any = {
            Action: "UnMute",
            Device: this.device,
            UUID: session.other_party_uuid
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendAgentEvaluateCommand = (callData: string) => {
        const session = currentSession();
        if (!session) {
            return false;
        }
        // const cmd = `Survey|${session.id}|${this.skillGroup}|${session.other_party_uuid}|${session.other_party}|mod_callcenter`;
        const cmd: any = {
            Action: "Survey",
            Device: this.device,
            ThisPartyUUID: session.id,
            DialPlan: "488",//满意度评分488
            OtherPartyUUID: session.other_party_uuid, //TODO 涉及转接后需要明确传递的是客户侧的uuid
            Caller: session.other_party,
            CallType: session.callerSource,
            Domain: this.config.domain,
            CallData: callData
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendConsultCallCommand = (target: string, callData: string, trunkNumber: string, gateway: string, outno: string) => {
        target = target?.trim();
        if (!target) {
            notifyError(ERROR_EMPTY_DEVICE, errorMessage(ERROR_EMPTY_DEVICE));
            return false;
        }
        const session = currentSession();
        if (!session) {
            return false;
        }
        trunkNumber = trunkNumber?.trim();
        gateway = gateway?.trim();
        outno = outno?.trim();
        // const cmd = `StartTransfer|${this.agentID}|${session.id}|${target}|${session.other_party}|${addHostExtension(target, this.config.domain)}`;
        const cmd: any = {
            Action: "Consult",
            Device: this.device,
            ThisPartyUUID: session.id,
            Destination: target,
            OriginalCaller: !!trunkNumber ? trunkNumber : this.config.trunkNumber,
            OriginalGateway: !!gateway ? gateway : this.config.gateway,
            OriginalOutno: !!outno ? outno : this.config.outno,
            AgentId: this.agentID,
            OtherPartyUUID: session.other_party_uuid,
            CallData: callData
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendRetrieveCallCommand = () => {
        const session = currentSession();
        if (!session) {
            return false;
        }
        // const cmd = `Retrieve|${this.agentID}|${session.id}`;
        const cmd: any = {
            Action: "Retrieve",
            Device: this.device,
            UUID: session.id
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendCompleteTransferCommand = () => {
        const session = currentSession();
        if (!session) {
            return false;
        }
        // const cmd = `CompleteTransfer|${this.agentID}|${session.id}`;
        const cmd: any = {
            Action: "CompleteTransfer",
            Device: this.device,
            UUID: session.id
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendVoiceChangeThickCommand = (destType: string, value: string, isChangeRecord: string) => {
        const session = currentSession();
        const cmd: any = {
            Action: "VoiceChangeThick",
            Device: this.device,
            Domain: this.config.domain,
            Value: value,
            DestType: destType,
            OtherPartyUUID: session.other_party_uuid,
            IsChangeRecord: isChangeRecord
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendVoiceChangeThinCommand = (destType: string, value: string, isChangeRecord: string) => {
        const session = currentSession();
        const cmd: any = {
            Action: "VoiceChangeThin",
            Device: this.device,
            Domain: this.config.domain,
            Value: value,
            DestType: destType,
            OtherPartyUUID: session.other_party_uuid,
            IsChangeRecord: isChangeRecord
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendCompleteThreePartiesCommand = () => {
        const session = currentSession();
        if (!session) {
            return false;
        }
        // const cmd = `CompleteConference|${this.agentID}|${session.id}`;
        const cmd: any = {
            Action: "CompleteConference",
            Device: this.device,
            UUID: session.id
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendSingleStepTransferCommand = (target: string, destType: string, callData: string, trunkNumber: string, gateway: string, outno: string) => {
        const session = currentSession();
        if (!session) {
            return false;
        }
        trunkNumber = trunkNumber?.trim();
        gateway = gateway?.trim();
        outno = outno?.trim();
        const cmd: any = {
            Action: "SingleStepTransfer",
            Device: this.device,
            ThisPartyUUID: session.id,
            OtherPartyUUID: session.other_party_uuid,
            Destination: target,
            DestType: destType,
            OriginalCaller: !!trunkNumber ? trunkNumber : this.config.trunkNumber,
            OriginalGateway: !!gateway ? gateway : this.config.gateway,
            OriginalOutno: !!outno ? outno : this.config.outno,
            Domain: this.config.domain,
            CallData: callData
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    }

    sendRequestVideoModeCommand = () => {
        const cmd: any = {
            Action: "RequestVideoMode",
            Device: this.device,
            Domain: this.config.domain
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };
    sendRequestAudioModeCommand = () => {
        const cmd: any = {
            Action: "RequestAudioMode",
            Device: this.device,
            Domain: this.config.domain
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendStartPushVideoStream = (streamPath: string) => {
        const cmd: any = {
            Action: "StartPushVideoStream",
            Device: this.device,
            StreamPath: streamPath,
            Domain: this.config.domain
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendStopPushMedia = () => {
        const cmd: any = {
            Action: "StopPushMedia",
            Device: this.device,
            Domain: this.config.domain
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendStartPushMediaFile = (filename: string) => {
        const cmd: any = {
            Action: "StartPushMediaFile",
            Device: this.device,
            FileName: filename,
            Domain: this.config.domain
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };
    sendHangupCallCommand = () => {
        const session = currentSession();
        if (!session) {
            return false;
        }
        // const cmd = `Hangup|${session.id}`;
        const cmd: any = {
            Action: "Hangup",
            UUID: session.id
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendForceBusyCommand = (peerAgentID: string) => {
        const cmd: any = {
            Action: "ForceBusy",
            FormanDevice: getAccessClient().getDevice(),
            AgentDevice: peerAgentID, // 被设置的分机
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendForceFreeCommand = (peerAgentID: string) => {
        const cmd: any = {
            Action: "ForceFree",
            FormanDevice: getAccessClient().getDevice(),
            AgentDevice: peerAgentID, // 被设置的分机
            Status: this.config.isAfterCall ? STATUS_READY : STATUS_INCALL_READY
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendLogoutForceCommand = (peerAgentID: string) => {
        const cmd: any = {
            Action: "ForceLogout",
            FormanDevice: getAccessClient().getDevice(),
            AgentDevice: peerAgentID, // 被设置的分机
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendStartDetectSpeechCommand = (call_uuid: string) => {
        const cmd: any = {
            Action: "StartDetectSpeech",
            Device: this.device,
            Domain: this.config.domain,
            UUID: call_uuid
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendForceListenCommand = (peerAgentID: string) => {
        const cmd: any = {
            Action: "ForceListen",
            FormanDevice: this.device,
            AgentDevice: peerAgentID, // 被设置的座席分机
            Domain: this.config.domain
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendForceConferenceCommand = (peerAgentID: string) => {
        const cmd: any = {
            Action: "ForceConference",
            FormanDevice: this.device,
            AgentDevice: peerAgentID, // 被设置的座席分机
            Domain: this.config.domain
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendForceWhisperCommand = (peerAgentID: string) => {
        const cmd: any = {
            Action: "ForceWhisper",
            FormanDevice: this.device,
            AgentDevice: peerAgentID, // 被设置的座席分机
            Domain: this.config.domain
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendForceInterceptCommand = (peerAgentID: string) => {
        const cmd: any = {
            Action: "ForceIntercept",
            FormanDevice: this.device,
            AgentDevice: peerAgentID, // 被设置的座席分机
            Domain: this.config.domain
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendProxyAnswerCommand = (peerAgentID: string) => {
        const cmd: any = {
            Action: "ProxyAnswer",
            Device: this.device,
            OtherDevice: peerAgentID, // 被设置的座席分机
            Domain: this.config.domain
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    sendForceHangUpCommand = (peerAgentID: string) => {
        const cmd: any = {
            Action: "ForceHangUp",
            AgentDevice: peerAgentID, // 被设置的座席分机
        };
        packCmdLog(cmd);
        this.ws.send(JSON.stringify(cmd));
        return true;
    };

    setAccessServerConnectedListener = (listener: () => void) => {
        this.connectedListener = listener;
    };

    setAccessServerDisConnectedListener = (listener: () => void) => {
        this.disconnectedListener = listener;
    };

    setAccessServerErrorListener = (listener: () => void) => {
        this.errorListener = listener;
    };

    private receive = async (ev: MessageEvent<any>) => {
        try {
            traceCallControl(`[接收指令]:< ${ev.data} >`);
            if (!!ev.data) {
                await processEvent(JSON.parse(ev.data));
            }
        } catch (ex) {
            if (ex instanceof Error) {
                error(`processEvent ${ev.data}发生异常, 错误信息: ${ex.stack}`);
            } else {
                error(`processEvent ${ev.data}发生异常, 错误信息: ${JSON.stringify(ex)}`);
            }
        }
    };

    private cleanup() {
        this.ws = undefined;
        this.agentID = "";
        this.skillGroup = "";
        this.mntForceDest = "";
        this.mntForceBehaviour = "";
        this.isMonitor = false;
        destroySessionByWbsocket();
    }

    private notifyUI = (code: number) => {
        // assert(WS_CLOSE_NORMAL <= code && code <= WS_CLOSE_BY_UNREG, `未知的WebSocket关闭code: ${code}`);
        const e: AgentLogoutEvent = {
            type: "Logout",
            agent_id: this.agentID,
            device: this.device,
            code,
            cause: wsCloseCause(code),
            allow: getAllowByStatus(STATUS_LOGOUT),
            date_time: dayjs().format("YYYY-MM-DD h:mm:ss")
        };
        getAccessClient().setMyAllowOperate(getAllowOperationKeyByStatus(STATUS_LOGOUT));
        getAgentEventListener(e.type)(e);
    }

    private isConnected = () => this.ws?.readyState === WebSocket.OPEN;
    private isConnecting = () => this.ws?.readyState === WebSocket.CONNECTING;

    private readonly config: AgentConfiguration;
    private readonly URL: string;
    private ws: WebSocket | undefined;
    private agentID = "";
    private device = "";
    private skillGroup = "";
    public isMonitor = false;
    private connectedListener = () => { };
    private disconnectedListener = () => { };
    private errorListener = () => { };
    private myAllowOperate: AllowOperationKey;
    public mntForceDest = "";//班长强制对象
    public mntForceBehaviour = "";

    setMyAllowOperate(allow: AllowOperationKey) {
        this.myAllowOperate = allow;
    }
    getMyAllowOperate(): AllowOperationKey {
        return this.myAllowOperate;
    }
    getSkillGroup(): string {
        return this.skillGroup;
    }
    getAgentId(): string {
        return this.agentID;
    }
    getDevice(): string {
        return this.device;
    }
}

// factory
let accessClient: AccessWebSocketClient;

export const createAccessClient = (c: AgentConfiguration): AccessWebSocketClient => {
    if (accessClient && accessClient.hasWebSocket()) {
        // traceWebSocket(`createAccessClient时已有连接, 先断连现有连接`);
        // accessClient.disconnect(WS_CLOSE_DUPLICATE);
        return accessClient;
    } else {
        accessClient = new AccessWebSocketClient(c);
        return accessClient;
    }
};

export const closeAccessClient = (): void => {
    if (accessClient && accessClient.hasWebSocket()) {
        traceWebSocket(`登录失败closeAccessClient`);
        accessClient.disconnect(WS_CLOSE_NORMAL);
    }
};

export const getAccessClient = () => {
    return accessClient;
}

const getStateText = (ws: WebSocket) => {
    if (ws === undefined) return "未创建";
    if (ws.readyState === WebSocket.CONNECTING) return "连接中";
    if (ws.readyState === WebSocket.OPEN) return "已连接";
    if (ws.readyState === WebSocket.CLOSING) return "关闭中";
    if (ws.readyState === WebSocket.CLOSED) return "已关闭";
    return `未知状态-${ws.readyState}`;
}

const packCmdLog = (cmd: any) => {
    traceCallControl(`[发送指令]:< ${JSON.stringify(cmd)} >`);
}

export const isIllegalOperation = (action: string) => {
    return false;
    if (accessClient) {
        if (accessClient.getMyAllowOperate()) {
            const len = allowOperation(accessClient.getMyAllowOperate()).filter(v => v === action).length;
            if (len > 0) {
                return false;
            } else {
                return true;
            }
        } else {
            if (action === "login") {
                return false;
            }
        }
        return true;
    } else {
        return false;
    }
}