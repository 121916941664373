import { AgentConfig } from "./configuration";
import { getAgentEventListener } from "./event_handler";
import { PublishStreamPrepareEvent } from "./types";

let elementMap = new Map<string, RTCPeerConnection>();
let streamMap = new Map<string, MediaStream>();

export function push_screen_capture_stream(elementId, url_route) {
    if (elementMap.has(elementId)) {
        close_agent_view(elementId);
    }

    const pc = new RTCPeerConnection();

    pc.oniceconnectionstatechange = () => {
        console.info('oniceconnectionstatechange', pc.iceConnectionState);
    };
    pc.onicecandidate = (e) => {
        console.info('onicecandidate', e.candidate);
    };
    elementMap.set(elementId, pc);
    (async () => {
        const mediaStream: MediaStream = await (navigator.mediaDevices.getDisplayMedia({
            audio: true,
            video: true//{ width: { max: 1280 }, height: { max: 720 }, frameRate: { ideal: 15 } }
        }));
        (document.getElementById(elementId) as any).srcObject = mediaStream;
        (document.getElementById(elementId) as any).play();

        mediaStream.getTracks().forEach((t) => {
            pc.addTrack(t, mediaStream);
        });

        mediaStream.getVideoTracks()[0].onended = () => {
            close_agent_view(elementId);
        }
        streamMap.set(elementId, mediaStream);
        const offer = await pc.createOffer();
        await pc.setLocalDescription(offer);

        let url = AgentConfig.videoPublishUrl;
        if (url.endsWith("/")) {
            url += url_route;
        } else {
            url += "/" + url_route;
        }
        const result = await fetch(
            url,
            {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'include',
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                headers: { 'Content-Type': 'application/sdp' },
                body: offer.sdp,
            },
        );
        const remoteSdp = await result.text();
        console.info("remoteSdp", remoteSdp);
        await pc.setRemoteDescription(new RTCSessionDescription({ type: 'answer', sdp: remoteSdp }), () => {
            const temp: PublishStreamPrepareEvent = {
                type: "PublichStreamPrepareEvent",
                stream_path: url_route
            };
            getAgentEventListener(temp.type)(temp);
        }, (error: any) => {
            getAgentEventListener("VideoStreamErrorEvent" as any)(error);
        });
    })();
}

export function close_agent_view(elementId: string) {
    if (elementMap.has(elementId)) {
        const mediaStream: MediaStream = streamMap.get(elementId);
        if (mediaStream) {
            mediaStream.getTracks().forEach((t) => {
                t.stop();
            });
        }
        const pc = elementMap.get(elementId);
        if (pc) {
            pc.close();
        }
        (document.getElementById(elementId) as any).srcObject = null;
        elementMap.delete(elementId);
        streamMap.delete(elementId);
    }
}