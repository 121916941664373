import { AgentConfig } from "../configuration";
import { WebPhoneDevice } from "./webrtc";

let device: any;

export const createWebPhoneDevice = (
    sipExtension: string,
    password: string,
    vdoView: string,
    selView: string,
    isVideo: boolean) => {
    device = new WebPhoneDevice(document.getElementById(vdoView) as HTMLMediaElement,
        document.getElementById(selView) as HTMLMediaElement, {
        sipExtension,
        sipHost: AgentConfig.sipHost,
        sipPort: AgentConfig.webrtcWssPort,
        sipWebSocketHost: AgentConfig.webrtcWssDomain,
        sipWebSocketPort: AgentConfig.webrtcWssPort,
        sipWebsocketSSL: true,
        password,
        realm: AgentConfig.sipHost,
        video: false,
        isAutoAnswer: true,
    });
    return device;
}

export const getWebPhoneDevice = () => device;
