import {
    assert,
} from "../types";
import {
    AgentErrorEvent,
    AgentNullEvent,
    AgentCallHoldEvent,
    AgentCallUnHoldEvent,
    AgentStatusChangeEvent,
    AgentCallRingingEvent,
    AgentCallHangupEvent,
    AgentLogoutEvent,
    AgentEventListenerType,
    AgentLoginFailedEvent,
    EnumAgentEventType,
    AgentLoginSuccessEvent,
    AgentCallRingBackEvent,
    AgentCallConsultEvent,
    OtherPartyCanceledEvent,
    ThisPartyAnsweredEvent,
    OtherPartyAnsweredEvent,
    RecvDtmfEvent,
    MonitorForceActionAnsweredEvent,
    MembersCountEvent,
    AgentOnCallEvent,
    AgentCallMuteEvent,
    AgentCallUnMuteEvent,
    StartVideoPullEvent,
    StopVideoPullEvent,
    AgentDetectSpeechEvent,
    StartVideoPushEvent,
    StopVideoPushEvent,
    PublishStreamPrepareEvent,
    VoiceChangeEvent,
    AllQueueListEvent,
    AllQueueAgentMapEvent
} from "../types/types_code";

const listeners: { [k: string]: any } = {
    LoginSuccessEvent: (e: AgentLoginSuccessEvent): void => { },
    LoginFailedEvent: (e: AgentLoginFailedEvent): void => { },
    Logout: (e: AgentLogoutEvent): void => { },
    AgentStatusEvent: (e: AgentStatusChangeEvent): void => { },
    RingBackEvent: (e: AgentCallRingBackEvent): void => { },
    RingingEvent: (e: AgentCallRingingEvent): void => { },
    ThisPartyAnsweredEvent: (e: ThisPartyAnsweredEvent): void => { },
    MonitorForceActionAnsweredEvent: (e: MonitorForceActionAnsweredEvent): void => { },
    OtherPartyAnsweredEvent: (e: OtherPartyAnsweredEvent): void => { },
    HangupEvent: (e: AgentCallHangupEvent): void => { },
    ConsultEvent: (e: AgentCallConsultEvent): void => { },
    AgentOnCallEvent: (e: AgentOnCallEvent): void => { },
    HoldEvent: (e: AgentCallHoldEvent): void => { },
    UnholdEvent: (e: AgentCallUnHoldEvent): void => { },
    MuteEvent: (e: AgentCallMuteEvent): void => { },
    UnMuteEvent: (e: AgentCallUnMuteEvent): void => { },
    RecvDtmfEvent: (e: RecvDtmfEvent): void => { },
    MembersCountEvent: (e: MembersCountEvent): void => { },
    OtherPartyCanceledEvent: (e: OtherPartyCanceledEvent): void => { },
    AgentErrorEvet: (e: AgentErrorEvent): void => { },
    AgentNull: (e: AgentNullEvent): void => { },
    StartVideoPullEvent: (e: StartVideoPullEvent): void => { },
    StopVideoPullEvent: (e: StopVideoPullEvent): void => { },
    DetectSpeechEvent: (e: AgentDetectSpeechEvent): void => { },
    StartVideoPushEvent: (e: StartVideoPushEvent): void => { },
    StopVideoPushEvent: (e: StopVideoPushEvent): void => { },
    VideoStreamErrorEvent: (e: any): void => { },
    VoiceChangeEvent: (e: VoiceChangeEvent): void => { },
    AllQueueListEvent: (e: AllQueueListEvent): void => { },
    AllQueueAgentMapEvent: (e: AllQueueAgentMapEvent): void => { },
    PublishStreamPrepareEvent: (e: PublishStreamPrepareEvent): void => { },
};

export const addVoiceChangeEventListener = (l: (e: VoiceChangeEvent) => void) => {
    listeners.VoiceChangeEvent = l;
};

export const addPublishStreamPrepareEventListener = (l: (e: PublishStreamPrepareEvent) => void) => {
    listeners.PublichStreamPrepareEvent = l;
};

export const addStartVideoPushEventListener = (l: (e: StartVideoPushEvent) => void) => {
    listeners.StartVideoPushEvent = l;
};

export const addStopVideoPushEventListener = (l: (e: StopVideoPushEvent) => void) => {
    listeners.StopVideoPushEvent = l;
};

export const addStartVideoPullEventListener = (l: (e: StartVideoPullEvent) => void) => {
    listeners.StartVideoPullEvent = l;
};

export const addStopVideoPullEventListener = (l: (e: StopVideoPullEvent) => void) => {
    listeners.StopVideoPullEvent = l;
};

export const addLoginSuccessEventListener = (l: (e: AgentLoginSuccessEvent) => void) => {
    listeners.LoginSuccessEvent = l;
};

export const addLoginFailedEventListener = (l: (e: AgentLoginFailedEvent) => void) => {
    listeners.LoginFailedEvent = l;
};

export const addLogoutEventListener = (l: (e: AgentLogoutEvent) => void) => {
    listeners.Logout = l;
};

export const addAgentStatusChangeEventListener = (l: (e: AgentStatusChangeEvent) => void) => {
    listeners.AgentStatusEvent = l;
};

export const addCallRingingEventListener = (l: (e: AgentCallRingingEvent) => void) => {
    listeners.RingingEvent = l;
};

export const addDetectSpeechEventListener = (l: (e: AgentDetectSpeechEvent) => void) => {
    listeners.DetectSpeechEvent = l;
};

export const addVideoStreamErrorListener = (l: (e: any) => void) => {
    listeners.VideoStreamErrorEvent = l;
};

export const addCallRingBackEventListener = (l: (e: AgentCallRingBackEvent) => void) => {
    listeners.RingBackEvent = l;
};

export const addThisPartyAnsweredEventListener = (l: (e: ThisPartyAnsweredEvent) => void) => {
    listeners.ThisPartyAnsweredEvent = l;
};

export const addMonitorForceActionAnsweredEventListener = (l: (e: MonitorForceActionAnsweredEvent) => void) => {
    listeners.MonitorForceActionAnsweredEvent = l;
};

export const addOtherPartyAnsweredEventListener = (l: (e: OtherPartyAnsweredEvent) => void) => {
    listeners.OtherPartyAnsweredEvent = l;
};

export const addCallHangupEventListener = (l: (e: AgentCallHangupEvent) => void) => {
    listeners.HangupEvent = l;
};

export const addCallConsultEventListener = (l: (e: AgentCallConsultEvent) => void) => {
    listeners.ConsultEvent = l;
};

export const addAgentOnCallEventListener = (l: (e: AgentOnCallEvent) => void) => {
    listeners.AgentOnCallEvent = l;
};

export const addCallOtherPartyCanceledEventListener = (l: (e: OtherPartyCanceledEvent) => void) => {
    listeners.OtherPartyCanceledEvent = l;
};

export const addCallHoldEventListener = (l: (e: AgentCallHoldEvent) => void) => {
    listeners.HoldEvent = l;
};

export const addCallMuteEventListener = (l: (e: AgentCallMuteEvent) => void) => {
    listeners.MuteEvent = l;
};

export const addRecvDtmfEventListener = (l: (e: RecvDtmfEvent) => void) => {
    listeners.RecvDtmfEvent = l;
};

export const addCallUnHoldEventListener = (l: (e: AgentCallUnHoldEvent) => void) => {
    listeners.UnholdEvent = l;
};

export const addCallUnMuteEventListener = (l: (e: AgentCallUnMuteEvent) => void) => {
    listeners.UnMuteEvent = l;
};

export const addMembersCountEventListener = (l: (e: MembersCountEvent) => void) => {
    listeners.MembersCountEvent = l;
};

export const addAgentErrorEventListener = (l: (e: AgentErrorEvent) => void) => {
    listeners.AgentErrorEvent = l;
};

export const addAllQueueListEventListener = (l: (e: AllQueueListEvent) => void) => {
    listeners.AllQueueListEvent = l;
};

export const addAllQueueAgentMapEventListener = (l: (e: AllQueueAgentMapEvent) => void) => {
    listeners.AllQueueAgentMapEvent = l;
};

export const getAgentEventListener = (type: EnumAgentEventType): AgentEventListenerType => {
    const listener = listeners[type];
    assert(!!listener, `getAgentEventListener ${type} is undefined`);
    return listener;
};
