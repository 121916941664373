import { getAccessClient } from "../web_socket_client";
import { assert, traceSimpleEvent } from "./debug";
import { AllowOperation, EnumAgentStatus, STATUS_LOGIN, STATUS_LOGOUT, STATUS_INCALL_READY, STATUS_READY, STATUS_RINGING, STATUS_TALKING, STATUS_DIALING } from "./types_code";

type AllowOperationMap = Readonly<{
    S_RINGING: ReadonlyArray<AllowOperation>;
    S_TALKING: ReadonlyArray<AllowOperation>;
    S_HOLD: ReadonlyArray<AllowOperation>;
    S_MUTE: ReadonlyArray<AllowOperation>;
    S_HOLD_DIALING: ReadonlyArray<AllowOperation>;
    S_HOLD_CONSULT: ReadonlyArray<AllowOperation>;
    S_INSIDE_DIALING: ReadonlyArray<AllowOperation>;
    S_CONSULT_IN: ReadonlyArray<AllowOperation>;
    S_THREE_PARTIES: ReadonlyArray<AllowOperation>;
    S_CONFERENCING: ReadonlyArray<AllowOperation>;
    S_HANGUP_CONSULT: ReadonlyArray<AllowOperation>;
    S_HANGUP_DIALING: ReadonlyArray<AllowOperation>;
    S_HANGUP: ReadonlyArray<AllowOperation>;
    AGENT_LOGIN: ReadonlyArray<AllowOperation>;
    AGENT_LOGOUT: ReadonlyArray<AllowOperation>;
    AGENT_NOT_READY: ReadonlyArray<AllowOperation>;
    AGENT_READY: ReadonlyArray<AllowOperation>;
    MONITOR_READY: ReadonlyArray<AllowOperation>;
    MONITOR_INCALL: ReadonlyArray<AllowOperation>;
    MONITOR_SNEAK: ReadonlyArray<AllowOperation>;
    MONITOR_HANGUP: ReadonlyArray<AllowOperation>;
}>;

export type AllowOperationKey = keyof AllowOperationMap;

const mapping: AllowOperationMap = {
    S_RINGING: ["answer", "hangup"],//振铃通话
    S_TALKING: ["hold", "mute", "consult", "hangup", "single_step_transfer", "single_step_transfer_phone", "transfer_agent_group", "agent_evaluate"],//通话中
    S_HOLD: ["unhold"],//保持
    S_MUTE: ["unmute"],//静音
    S_HOLD_DIALING: ["cancel_consult"],//发起磋商阶段
    S_HOLD_CONSULT: ["complete_transfer", "complete_three_parties", "cancel_consult"],//磋商成功阶段
    S_INSIDE_DIALING: ["hangup"],//内部通话挂断
    S_THREE_PARTIES: ["hangup"],//三方通话中时挂断
    S_HANGUP: ["make", "proxy_answer", "status", "logout"],//挂机后
    AGENT_LOGOUT: ["login"],//坐席登出状态
    AGENT_LOGIN: ["logout", "status", "make", "proxy_answer"],//登入后
    AGENT_NOT_READY: ["logout", "status", "make", "proxy_answer"],//坐席在未就绪(包括在忙其他事情)状态下
    AGENT_READY: ["logout", "status", "make", "proxy_answer"],//坐席在已就绪状态下
    MONITOR_SNEAK: ["hangup"],//班长做偷窥时MONITOR_INCALL
    MONITOR_READY: ["force_busy", "force_free", "force_logout"],
    MONITOR_INCALL: ["force_listen", "force_whisper", "force_conference", "force_intercept", "force_hangup"],
    MONITOR_HANGUP: ["force_hangup"],

    //以下未使用
    S_HANGUP_CONSULT: ["hangup"],
    S_CONSULT_IN: ["hangup"],
    S_CONFERENCING: ["add_conference_member", "leave_conference"],
    S_HANGUP_DIALING: ["cancel_consult", "logout"],
};

export const getAllowByStatus = (status: EnumAgentStatus) => {
    if (status === STATUS_LOGIN) {
        return allowOperation("AGENT_LOGIN");
    } else if (status === STATUS_LOGOUT) {
        return allowOperation("AGENT_LOGOUT");
    } else if (status === STATUS_READY || status === STATUS_INCALL_READY) {
        return allowOperation("AGENT_READY");
    } else if (status === STATUS_RINGING || status === STATUS_DIALING) {
        return allowOperation("S_RINGING");
    } else if (status === STATUS_TALKING) {
        return allowOperation("S_CONSULT_IN");
    } else {
        traceSimpleEvent(`当前STATUS: ${status}`);
        return allowOperation("AGENT_NOT_READY");
    }
}

export const getAllowOperationKeyByStatus = (status: EnumAgentStatus) => {
    if (status === STATUS_LOGIN) {
        return "AGENT_LOGIN";
    } else if (status === STATUS_LOGOUT) {
        return "AGENT_LOGOUT";
    } else if (status === STATUS_READY || status === STATUS_INCALL_READY) {
        return "AGENT_READY";
    } else if (status === STATUS_RINGING || status === STATUS_DIALING) {
        return "S_RINGING";
    } else if (status === STATUS_TALKING) {
        return "S_CONSULT_IN";
    } else {
        return "AGENT_NOT_READY";
    }
}

export const allowOperation = (key: AllowOperationKey) => {
    let monitor_allow = [];
    if (key === "AGENT_LOGIN" || key === "AGENT_READY" || key === "AGENT_NOT_READY") {
        monitor_allow = [...mapping["MONITOR_READY"], ...mapping["MONITOR_INCALL"]];
    } else if (key === "MONITOR_SNEAK") {
        // monitor_allow = [...mapping["MONITOR_INCALL"]];
        monitor_allow = [...mapping["MONITOR_HANGUP"]];
    }
    const allow = [...mapping[key], ...getAccessClient()?.isMonitor ? monitor_allow : []];
    if (allow) {
        return allow;
    } else {
        assert(false, `allowOperation错误的参数[${key}]`);
        return [];
    }
}

