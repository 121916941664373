import * as API from "../api/agent_control";
import {
    ERROR_AGENT_ID,
    ERROR_AGENT_OR_PASSWORD,
    ERROR_DEVICE_ID,
    ERROR_IN_LOGIN,
    ERROR_OPERATION_HEHAVIOR,
    ERROR_PARAMS_EMPTY,
    ERROR_TARGET_NUMBER,
    allowOperation,
    errorMessage,
    notifyError,
} from "../types";
import {
    addAgentErrorEventListener,
    addAgentStatusChangeEventListener,
    addAllQueueAgentMapEventListener,
    addAllQueueListEventListener,
    addLoginFailedEventListener,
    addLoginSuccessEventListener,
    addLogoutEventListener,
    addMembersCountEventListener,
    addMonitorForceActionAnsweredEventListener,
} from "../event_handler";
import { createAccessClient, getAccessClient, isIllegalOperation } from "../web_socket_client";
import { AgentConfig } from "../configuration";
import {
    AgentErrorEvent,
    LoginQueue,
    EnumAgentStatus,
    AgentStatusChangeEvent,
    AgentLogoutEvent,
    AgentLoginFailedEvent,
    AgentLoginSuccessEvent,
    WS_CLOSE_NORMAL,
    MonitorForceActionAnsweredEvent,
    MembersCountEvent,
    STATUS_READY,
    STATUS_INCALL_READY,
    AllQueueListEvent,
    AllQueueAgentMapEvent
} from "../types/types_code";
import { addHostExtension, isExtensionNumber } from "../types/utils";
import { createWebPhoneDevice, getWebPhoneDevice } from "../web_phone_device";

export class VoiceCommAgentControl implements API.AgentControl {
    login(agentID: string, device: string, password: string, belong_queues: ReadonlyArray<LoginQueue>): Promise<void> {


        agentID = agentID?.trim().split('@')[0];
        password = password?.trim();

        if (!!device === false || (!!password === false)) {
            notifyError(ERROR_AGENT_OR_PASSWORD, errorMessage(ERROR_AGENT_OR_PASSWORD));
            return;
        }
        // if (belong_queues.length === 0) {
        //     notifyError(ERROR_BELONG_QUEUES, `工号${device}至少应该属于一个技能组`);
        //     return;
        // }
        if (this.loginState === "Logining") {
            notifyError(ERROR_IN_LOGIN, errorMessage(ERROR_IN_LOGIN));
            return;
        };

        if (!isExtensionNumber(agentID)) {
            notifyError(ERROR_AGENT_ID, errorMessage(ERROR_AGENT_ID));
            return;
        }
        if (!isExtensionNumber(device)) {
            notifyError(ERROR_DEVICE_ID, errorMessage(ERROR_DEVICE_ID));
            return;
        }
        // if (getAccessClient()) {
        //     if (isIllegalOperation("login")) {
        //         notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-login`);
        //         return;
        //     }
        // }
        var client = getAccessClient()
        if (client && client.hasWebSocket()) {
            return
        }
        client = createAccessClient(AgentConfig);
        const skillGroup = belong_queues.map(q => q.queue_id ? q.queue_id.split('@')[0] : q.queue_id)[0];
        const isMonitor = belong_queues.map(q => q.is_monitor)[0];
        client.isMonitor = isMonitor;

        const command = {
            Action: "Login",
            AgentId: `${agentID}@${AgentConfig.domain}`,
            Password: password,
            Device: device,
            Queue: addHostExtension(skillGroup, AgentConfig.domain),
            Status: "11"
        }
        client.setAccessServerConnectedListener(() => client.sendNewLoginCommand(command));

        if (AgentConfig.deviceType === "webphone") {
            const dev = createWebPhoneDevice(device, password, "", "", true);
            dev.setSIPRegisteredListener(() => getAccessClient().connect());
            dev.login();
        } else {
            client.connect();
        }
    }

    setVideoView(peer: string, local: string): boolean {
        getWebPhoneDevice().setVideoView(peer, local);
        return true;
    }

    logout(): boolean {
        let client = getAccessClient();
        if (isIllegalOperation("logout")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-logout`);
            return;
        }
        if (AgentConfig.deviceType === "webphone") {
            getWebPhoneDevice().logout();
        }
        if (client && client.hasWebSocket()) {
            client.disconnect(WS_CLOSE_NORMAL);
        }
        return true;
    }

    tierOn(peerAgentID: string, queue: string): boolean {
        peerAgentID = peerAgentID?.trim();
        queue = queue?.trim();
        if (!peerAgentID || !queue) {
            notifyError(ERROR_PARAMS_EMPTY, errorMessage(ERROR_PARAMS_EMPTY));
            return;
        }
        return getAccessClient().sendTierOn(peerAgentID, queue);
    }

    tierOff(peerAgentID: string, queue: string): boolean {
        peerAgentID = peerAgentID?.trim();
        queue = queue?.trim();
        if (!peerAgentID || !queue) {
            notifyError(ERROR_PARAMS_EMPTY, errorMessage(ERROR_PARAMS_EMPTY));
            return;
        }
        return getAccessClient().sendTierOff(peerAgentID, queue);
    }

    queueDetail(): boolean {
        return getAccessClient().sendQueueDetail();
    }

    setStatus(status: EnumAgentStatus): boolean {
        if (isIllegalOperation("status")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-status`);
            return;
        }
        status = `${status}`;
        console.log("输入的变更状态: ", status);
        if (status === STATUS_READY || status === STATUS_INCALL_READY) {
            if (AgentConfig.isAfterCall) {
                status = STATUS_READY;
            } else {
                status = STATUS_INCALL_READY;
            }
            console.log("当前配置是否话后处理,", AgentConfig.isAfterCall, ";空闲状态为: ", status);
        }
        console.log("调整过后的变更状态: ", status);
        return getAccessClient().sendSetAgentStatusCommand(status);
    }

    forceBusy(peerAgentID: string): boolean {
        if (isIllegalOperation("force_busy")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-force_busy`);
            return;
        }
        peerAgentID = peerAgentID?.trim();
        if (!isExtensionNumber(peerAgentID)) {
            notifyError(ERROR_TARGET_NUMBER, `${peerAgentID}不是有效的坐席号码`);
            return;
        }
        return getAccessClient().sendForceBusyCommand(peerAgentID);
    }

    forceFree(peerAgentID: string): boolean {
        if (isIllegalOperation("force_free")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-force_free`);
            return;
        }
        peerAgentID = peerAgentID?.trim();
        if (!isExtensionNumber(peerAgentID)) {
            notifyError(ERROR_TARGET_NUMBER, `${peerAgentID}不是有效的坐席号码`);
            return;
        }
        return getAccessClient().sendForceFreeCommand(peerAgentID);
    }

    forceLogout(peerAgentID: string): boolean {
        if (isIllegalOperation("force_logout")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-force_logout`);
            return;
        }
        peerAgentID = peerAgentID?.trim();
        if (!isExtensionNumber(peerAgentID)) {
            notifyError(ERROR_TARGET_NUMBER, `${peerAgentID}不是有效的坐席号码`);
            return;
        }
        return getAccessClient().sendLogoutForceCommand(peerAgentID);
    }

    forceListen(peerAgentID: string): boolean {
        if (isIllegalOperation("force_listen")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-force_listen`);
            return;
        }
        peerAgentID = peerAgentID?.trim();
        if (!isExtensionNumber(peerAgentID)) {
            notifyError(ERROR_TARGET_NUMBER, `${peerAgentID}不是有效的坐席号码`);
            return;
        }
        const client = getAccessClient();
        if (client.isThisDevice(peerAgentID)) {
            notifyError(ERROR_TARGET_NUMBER, `不能对本分机进行操作`);
            return;
        }
        getAccessClient().mntForceDest = peerAgentID;
        getAccessClient().mntForceBehaviour = "force_listen";
        return getAccessClient().sendForceListenCommand(peerAgentID);
    }

    forceConference(peerAgentID: string): boolean {
        if (isIllegalOperation("force_conference")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-force_conference`);
            return;
        }
        peerAgentID = peerAgentID?.trim();
        if (!isExtensionNumber(peerAgentID)) {
            notifyError(ERROR_TARGET_NUMBER, `${peerAgentID}不是有效的坐席号码`);
            return;
        }
        const client = getAccessClient();
        if (client.isThisDevice(peerAgentID)) {
            notifyError(ERROR_TARGET_NUMBER, `不能对本分机进行操作`);
            return;
        }
        getAccessClient().mntForceDest = peerAgentID;
        getAccessClient().mntForceBehaviour = "force_conference";
        return getAccessClient().sendForceConferenceCommand(peerAgentID);
    }

    forceWhisper(peerAgentID: string): boolean {
        if (isIllegalOperation("force_whisper")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-force_whisper`);
            return;
        }
        peerAgentID = peerAgentID?.trim();
        if (!isExtensionNumber(peerAgentID)) {
            notifyError(ERROR_TARGET_NUMBER, `${peerAgentID}不是有效的坐席号码`);
            return;
        }
        const client = getAccessClient();
        if (client.isThisDevice(peerAgentID)) {
            notifyError(ERROR_TARGET_NUMBER, `不能对本分机进行操作`);
            return;
        }
        getAccessClient().mntForceDest = peerAgentID;
        getAccessClient().mntForceBehaviour = "force_whisper";
        return getAccessClient().sendForceWhisperCommand(peerAgentID);
    }

    forceIntercept(peerAgentID: string): boolean {
        if (isIllegalOperation("force_intercept")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-force_intercept`);
            return;
        }
        peerAgentID = peerAgentID?.trim();
        if (!isExtensionNumber(peerAgentID)) {
            notifyError(ERROR_TARGET_NUMBER, `${peerAgentID}不是有效的坐席号码`);
            return;
        }
        const client = getAccessClient();
        if (client.isThisDevice(peerAgentID)) {
            notifyError(ERROR_TARGET_NUMBER, `不能对本分机进行操作`);
            return;
        }
        getAccessClient().mntForceDest = peerAgentID;
        getAccessClient().mntForceBehaviour = "force_intercept";
        return getAccessClient().sendForceInterceptCommand(peerAgentID);
    }

    forceHangUp(peerAgentID: string): boolean {
        if (isIllegalOperation("force_hangup")) {
            notifyError(ERROR_OPERATION_HEHAVIOR, `允许的操作[${allowOperation(getAccessClient().getMyAllowOperate()).join(",")}],错误的操作行为-force_hangup`);
            return;
        }
        peerAgentID = peerAgentID?.trim();
        if (!isExtensionNumber(peerAgentID)) {
            notifyError(ERROR_TARGET_NUMBER, `${peerAgentID}不是有效的坐席号码`);
            return;
        }
        const client = getAccessClient();
        if (client.isThisDevice(peerAgentID)) {
            notifyError(ERROR_TARGET_NUMBER, `不能对本分机进行操作`);
            return;
        }
        return getAccessClient().sendForceHangUpCommand(peerAgentID);
    }

    setAgentLoginSuccessEventListener(listener: (event: AgentLoginSuccessEvent) => void): void {
        addLoginSuccessEventListener(listener);
    }

    setAgentLoginFailedEventListener(listener: (event: AgentLoginFailedEvent) => void): void {
        addLoginFailedEventListener(listener);
    }

    setAgentLogoutEventListener(listener: (event: AgentLogoutEvent) => void): void {
        addLogoutEventListener(listener);
    }

    setAgentStatusChangeEventListener(listener: (event: AgentStatusChangeEvent) => void): void {
        addAgentStatusChangeEventListener(listener);
    }

    setAgentErrorEventListener(listener: (event: AgentErrorEvent) => void): void {
        addAgentErrorEventListener(listener);
    }

    setMembersCountEventListener(listener: (event: MembersCountEvent) => void): void {
        addMembersCountEventListener(listener);
    }

    setMonitorForceActionAnsweredEvent(listener: (event: MonitorForceActionAnsweredEvent) => void): void {
        addMonitorForceActionAnsweredEventListener(listener);
    }

    setAllQueueListEventListener(listener: (event: AllQueueListEvent) => void): void {
        addAllQueueListEventListener(listener);
    }

    setAllQueueAgentMapEventListener(listener: (event: AllQueueAgentMapEvent) => void): void {
        addAllQueueAgentMapEventListener(listener);
    }

    private loginState: "Ready" | "Logining" = "Ready";

}
